

import { ApiService } from '../services/api'
export const exam_sittings = {
    namespaced: true,
    state: {
        currentRecord: null,
        records: [],
        requestError: null,
        shsCourseList: [],
        gradeScoresList: [],
    },
    getters: {
        records(state) {
            return state.records
        },
        currentRecord(state) {
            return state.currentRecord
        },
        shsCourseList(state) {
            return state.shsCourseList;
        },
        gradeScoresList(state) {
            return state.gradeScoresList;
        },
    },
    mutations: {
        setRecords(state, records) {
            state.records = records
        },
        setCurrentRecord(state, record) {
            state.currentRecord = record;
        },
        mergeRecords(state, records) {
            state.records = state.records.concat(records);
        },
        setShsCourseList(state, data) {
            state.shsCourseList = data;
        },
        setGradeScoresList(state, data) {
            state.gradeScoresList = data;
        },
        addRecord(state, record) {
            if (Array.isArray(record)) {
                for (let index = 0; index < record.length; index++) {
                    state.records.unshift(record[index]);
                }
            }
            else {
                state.records.unshift(record);
                state.currentRecord = record;
            }
        },
        updateRecord(state, record) {
            const item = state.records.find(item => item['institution_campus_id'] === record['institution_campus_id']);
            if (item) {
                Object.assign(item, record);
            }
        },
        setError(state, errors) {
            state.requestError = error
        },
    },
    actions: {
        fetchRecords: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                let url = payload.url;
                let merge = payload.merge;
                ApiService.get(url).then(resp => {
                    let data = resp.data;
                    if (data.records) {
                        let records = data.records;
                        if (merge) {
                            commit("mergeRecords", records);
                        }
                        else {
                            commit("setRecords", records);
                        }
                        resolve(data);
                    }
                    else {
                        reject("invalid json data");
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        fetchRecord: ({ commit }, url) => {
            return new Promise((resolve, reject) => {
                ApiService.get(url).then(resp => {
                    resolve(resp)
                })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        saveRecord: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                let url = data.url;
                let payload = data.payload;
                ApiService.post(url, payload).then(resp => {
                    let record = resp.data;
                    commit("addRecord", record);
                    resolve(resp);
                })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateRecord: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                let url = data.url;
                let payload = data.payload;
                ApiService.post(url, payload).then(resp => {
                    let record = resp.data;
                    commit("updateRecord", record);
                    resolve(resp)
                })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getShsCourses({ commit, state }) {
            return new Promise((resolve, reject) => {
                let url = "components_data/shs_courses_subjects_option_list";
                if (state.shsCourseList.length < 1) {
                    ApiService.get(url)
                        .then((resp) => {
                            commit("setShsCourseList", resp.data);
                            resolve(resp);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            });
        },
        getGradeScores({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                let url = data.url;
                ApiService.get(url)
                    .then((resp) => {
                        commit("setGradeScoresList", resp.data);
                        resolve(resp);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    }
}
