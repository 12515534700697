
import { i18n } from './services/i18n.js';

import countries_list from '../public/data/countries_list.json';
import days_list from '../public/data/days_list.json';
import dial_codes_list from '../public/data/dial_codes_list.json';
import entry_level_list from '../public/data/entry_level_list.json';
import entry_scenario_list from '../public/data/entry_scenario_list.json';
import network_list from '../public/data/network_list.json';
import occupations_list from '../public/data/occupations_list.json';
import regions_list from '../public/data/regions_list.json';
import religions_list from '../public/data/religions_list.json';
import shs_list from '../public/data/shs_list.json';
import tertiary_degree_list from '../public/data/tertiary_degree_list.json';
import tertiary_list from '../public/data/tertiary_list.json';
import title_list from '../public/data/title_list.json';
import years_list from '../public/data/years_list.json';

export const AppMenus = {
    // External JSON menus
    countries_list: countries_list,
    dial_codes_list: dial_codes_list,
    entry_level_list: entry_level_list,
    entry_scenario_list: entry_scenario_list,
    occupations_list: occupations_list,
    regions_list: regions_list,
    religions_list: religions_list,
    shs_list: shs_list,
    tertiary_degree_list: tertiary_degree_list,
    tertiary_list: tertiary_list,
    title_list: title_list,
    years_list: years_list,
    days_list: days_list,
    network_list: network_list,

    navbarTopRightItems: [],
    navbarTopLeftItems: [],
    navbarSideLeftItems: [],
    pg_entrylevelItems: [
        { "value": "Masters", "label": "Master's" },
        { "value": "PhD", "label": "PhD" },
    ],
    yes_or_noItems: [
        { value: "Yes", label: i18n.t('yes') },
        { value: "No", label: i18n.t('no') }
    ],
    learning_institutionItems: [
      { value: "Regular", label: "Regular" },
      { value: "Distance", label: "Distance" }
  ],
    workingExperienceDurationItems: [
        { value: "None", label: "None" },
        { value: "Less than a year", label: "Less than a year" },
        { value: "1 year", label: "1 year" },
        { value: "2 years", label: "2 years" },
        { value: "3 years", label: "3 years" },
        { value: "More than 3 years", label: "More than 3 years" },
    ],
    exam_sitting_monthItems: [
        { value: "May/June", label: "May/June" },
        { value: "Nov/Dec", label: "Nov/Dec" },
    ],
    same_as_personal_information_aboveItems: [
        { value: "Yes", label: i18n.t('same_as_personal_information_above') }
    ],
    same_as_address_aboveItems: [
        { value: "Yes", label: i18n.t('same_as_address_above') }
    ],
    ratingItems: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
    ],
    application_typeItems: [
        { value: "Undergraduate", label: i18n.t('undergraduate') },
        { value: "Postgraduate", label: i18n.t('postgraduate') },
        { value: "Mature", label: i18n.t('mature') },
        { value: "Topup", label: i18n.t('topup') },
    ],
    national_id_typeItems: [
        { value: "Ghana Card", label: i18n.t('ghana_card') },
        { value: "Passport", label: i18n.t('passport') },
        { value: "Birth Certificate", label: i18n.t('birth_certificate') },
        { value: "NHIS", label: i18n.t('NHIS') }
    ],
    postgraduate_national_id_typeItems: [
        { value: "Ghana Card", label: i18n.t('ghana_card') },
        { value: "Passport", label: i18n.t('passport') },
    ],
    tertiary_degree_classItems: [
        { value: "Distinction", label: i18n.t('distinction') },
        { value: "Pass with Distinction", label: i18n.t('Pass with Distinction') },
        { value: "First Class", label: i18n.t('first_class') },
        { value: "Second Class Upper", label: i18n.t('second_class_upper') },
        { value: "Second Class Lower", label: i18n.t('second_class_lower') },
        { value: "Third Class", label: i18n.t('third_class') },
        { value: "Merit", label: i18n.t('Merit') },
        { value: "Pass", label: i18n.t('pass') },
        { value: "Fail", label: i18n.t('Fail') },
        { value: "Other", label: i18n.t('Other') },
    ],
    tertiary_reason_for_leavingItems: [
        { value: "Completed", label: i18n.t('completed') },
        { value: "Withdrawal", label: i18n.t('withdrawal') },
        { value: "Other", label: i18n.t('other') }
    ],
    ghanaCardCountryItems: [
        { value: "GHA", label: "GHA", country: "Ghana" },
        { value: "NRG", label: "NRG", country: "Nigeria" },
    ],
    sponsorTypeItems: [
        { value: "Self", label: "Self" },
        { value: "Other", label: "Other" },
    ],
    downloadformTypeItems: [
        { value: "Masters", label: "Master's programme" },
        { value: "PhD", label: "GCTU PhD programme" },
    ],
    referralItems: [
        { value: "Website", label: "Website" },
        { value: "Television", label: "Television" },
        { value: "Recommendation", label: "Recommendation" },
        { value: "Radio", label: "Radio" },
        { value: "Friends", label: "Friends" },
        { value: "Brochures", label: "Brochures" },
        { value: "Outreach", label: "Outreach" },
        { value: "Education Fair", label: "Education Fair" },
    ],
    last_competitionItems: [
        { value: "Athletics", label: i18n.t('athletics') },
        { value: "Badminton", label: i18n.t('badminton') },
        { value: "Basketball", label: i18n.t('basketball') },
        { value: "Baseball", label: i18n.t('baseball') },
        { value: "Cross Country", label: i18n.t('cross_country') },
        { value: "Chess", label: i18n.t('chess') },
        { value: "Cricket", label: i18n.t('cricket') },
        { value: "Football", label: i18n.t('football') },
        { value: "Goalball", label: i18n.t('goalball') },
        { value: "Handball", label: i18n.t('handball') },
        { value: "Hockey", label: i18n.t('hockey') },
        { value: "Netball", label: i18n.t('netball') },
        { value: "Rugby", label: i18n.t('rugby') },
        { value: "Swimming", label: i18n.t('swimming') },
        { value: "Table Tennis", label: i18n.t('table_tennis') },
        { value: "Volleyball", label: i18n.t('volleyball') }
    ],
    shs_courseItems: [
        { value: "General Science", label: i18n.t('General Science') },
        { value: "Business", label: i18n.t('business') },
        { value: "General Arts", label: i18n.t('general_arts') },
        { value: "Visual Arts", label: i18n.t('visual_arts') },
        { value: "Home Economics", label: i18n.t('home_economics') },
        { value: "General Agriculture", label: i18n.t('General Agriculture') },
        { value: "Technical", label: i18n.t('technical') },
        { value: "Vocational", label: i18n.t('vocational') }
    ],
    shs_qualificationItems: [
        { value: "wassce", description: i18n.t('West African Senior School Certificate Examination'), label: i18n.t('WASSCE') },
        { value: "novdec", description: i18n.t('West African Senior School Certificate Examination'), label: i18n.t('WASSCE (NOV/DEC)') },
        { value: "sssce", description: i18n.t('Senior Secondary School Certificate Examination'), label: i18n.t('SSSCE') },
        { value: "sssce private", description: i18n.t('Senior Secondary School Certificate Examination'), label: i18n.t('SSSCE (NOV/DEC)') },
        { value: "nabptex", description: i18n.t('National Board for Professional and Technician Examinations'), label: i18n.t('CTVET/NABPTEX') },
        { value: "abce", description: i18n.t('advanced_business_certificate_examination'), label: i18n.t('ABCE') },
        { value: "abce private", description: i18n.t('advanced_business_certificate_examination'), label: i18n.t('ABCE (NOV/DEC)') },
        { value: "gbce", description: i18n.t('general_business_certificate_examination'), label: i18n.t('GBCE') },
        { value: "gbce private", description: i18n.t('general_business_certificate_examination'), label: i18n.t('GBCE (NOV/DEC)') },
        { value: "oalevel", description: i18n.t('O-level and A-Level Examination'), label: i18n.t('O/A LEVEL ') },
        { value: "other", description: i18n.t('exams_type_not_listed_here'), label: i18n.t('other_exams') }
    ],
    living_statusItems: [
        { value: "Alive", label: i18n.t('alive') },
        { value: "Deceased", label: i18n.t('deceased') }
    ],
    shs_or_tertiary_countryItems: [
        { value: "In Ghana", label: i18n.t('in_ghana') },
        { value: "Outside Ghana", label: i18n.t('outside_ghana') }
    ],
    genderItems: [
        { value: "Male", label: i18n.t('male') },
        { value: "Female", label: i18n.t('female') }
    ],
    marital_statusItems: [
        { value: "Married", label: i18n.t('married') },
        { value: "Single", label: i18n.t('single') },
        { value: "Divorced", label: i18n.t('divorced') },
        { value: "Separated", label: i18n.t('separated') }
    ],
    dob_monthItems: [
        { value: "January", label: i18n.t('january') },
        { value: "February", label: i18n.t('february') },
        { value: "March", label: i18n.t('march') },
        { value: "April", label: i18n.t('april') },
        { value: "May", label: i18n.t('may') },
        { value: "June", label: i18n.t('june') },
        { value: "July", label: i18n.t('july') },
        { value: "August", label: i18n.t('august') },
        { value: "September", label: i18n.t('september') },
        { value: "October", label: i18n.t('october') },
        { value: "November", label: i18n.t('november') },
        { value: "December", label: i18n.t('december') }
    ],
    expiry_monthItems: [
        { value: "01", label: "01" },
        { value: "02", label: "02" },
        { value: "03", label: "03" },
        { value: "04", label: "04" },
        { value: "05", label: "05" },
        { value: "06", label: "06" },
        { value: "07", label: "07" },
        { value: "08", label: "08" },
        { value: "09", label: "09" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" }
    ],
    expiry_yearItems: [
        { value: "35", label: i18n.t('2035') },
        { value: "34", label: i18n.t('2034') },
        { value: "33", label: i18n.t('2033') },
        { value: "32", label: i18n.t('2032') },
        { value: "31", label: i18n.t('2031') },
        { value: "30", label: i18n.t('2030') },
        { value: "29", label: i18n.t('2029') },
        { value: "28", label: i18n.t('2028') },
        { value: "27", label: i18n.t('2027') },
        { value: "26", label: i18n.t('2026') },
        { value: "25", label: i18n.t('2025') },
        { value: "24", label: i18n.t('2024') },
        { value: "23", label: i18n.t('2023') },
        { value: "22", label: i18n.t('2022') },
        { value: "21", label: i18n.t('2021') }
    ],
    disabilityItems: [
        {
            label: i18n.t('hearing_impairment'),
            items: [
                { value: "Hearing Impaired", label: i18n.t('hearing_impaired') },
                { value: "Deaf", label: i18n.t('deaf') },
            ],
        },
        {
            label: i18n.t('mobility_physical_impairment'),
            items: [
                { value: "Physically Handicapped", label: i18n.t('physically_handicapped') },
                { value: "Wheelchair Bound", label: i18n.t('wheelchair_bound') },
                { value: "Lame (In Crutches)", label: i18n.t('lame_in_crutches') },
                { value: "Lame (On Crutches)", label: i18n.t('lame_on_crutches') },
            ],
        },
        {
            label: i18n.t('speech_or_language_impairment'),
            items: [{ value: "Speech Impaired", label: i18n.t('speech_impaired') }],
        },
        {
            label: i18n.t('visual_impairment'),
            items: [
                { value: "Blind", label: i18n.t('blind') },
                { value: "Partially Sighted", label: i18n.t('partially_sighted') },
            ],
        },
        {
            label: i18n.t('other_impairment'),
            items: [{ value: "Other", label: i18n.t('other') }],
        },
    ],
    exportFormats: {
        print: {
            label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: '',
        },
        pdf: {
            label: 'Pdf',

            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
            label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
            label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    locales: {
        "en": "English",
        // "fr": "French"
    }
}