import { createRouter, createWebHashHistory } from "vue-router";

export default async ({ store }) => {
  let routes = [
    //applicants routes
    {
      path: "/applicants/edit",
      name: "applicantsedit",
      component: () => import("./pages/applicants/edit.vue"),
      props: true,
    },

    {
      path: "/applicants/biodata",
      name: "applicantsbiodata",
      component: () => import("./pages/applicants/biodata.vue"),
      props: true,
    },

    {
      path: "/applicants/familydata",
      name: "applicantsfamilydata",
      component: () => import("./pages/applicants/familydata.vue"),
      props: true,
    },

    {
      path: "/applicants/academicsdata",
      name: "applicantsacademicsdata",
      component: () => import("./pages/applicants/academicsdata.vue"),
      props: true,
    },

    {
      path: "/applicants/programmesdata",
      name: "applicantsprogrammesdata",
      component: () => import("./pages/applicants/programmesdata.vue"),
      props: true,
    },

    {
      path: "/applicants/fundingdata",
      name: "applicantsfundingdata",
      component: () => import("./pages/applicants/fundingdata.vue"),
      props: true,
    },

    {
      path: "/applicants/payment",
      name: "applicantspayment",
      component: () => import("./pages/applicants/payment.vue"),
      props: true,
    },
    {
      path: "/applicants/summarydata",
      name: "applicantssummarydata",
      component: () => import("./pages/applicants/summarydata.vue"),
      props: true,
    },

    {
      path: "/applicants/results",
      name: "applicantsresults",
      component: () => import("./pages/applicants/results.vue"),
      props: true,
    },

    {
      path: "/applicant_selected_grants/edit/:id",
      name: "applicant_selected_grantsedit",
      component: () => import("./pages/applicant_selected_grants/edit.vue"),
      props: true,
    },

    //Application Form Purchase Pages
    {
      path: "/index/purchaseform",
      name: "purchaseform",
      component: () => import("./pages/index/purchaseform.vue"),
      props: true,
    },
    {
      path: "/index/verifyotp",
      name: "verifyotp",
      component: () => import("./pages/index/verifyotp.vue"),
      props: true,
    },
    {
      path: "/index/confirmpurchase",
      name: "confirmpurchase",
      component: () => import("./pages/index/confirmpurchase.vue"),
      props: true,
    },
    {
      path: "/index/recommendation/:id",
      name: "applicantsrecommendation",
      component: () => import('./pages/index/recommendation.vue'),
      props: true
    },
    {
      path: "/index/howtoapply",
      name: "howtoapply",
      component: () => import("./pages/index/howtoapply.vue"),
      props: true,
    },

    {
      path: "/error/forbidden",
      name: "forbidden",
      component: () => import("./pages/errors/forbidden.vue"),
      props: true,
    },
    {
      path: "/error/notfound",
      name: "notfound",
      component: () => import("./pages/errors/pagenotfound.vue"),
      props: true,
    },
    {
      path: "/:catchAll(.*)",
      component: () => import("./pages/errors/pagenotfound.vue"),
    },
  ];

  let loginToken = store.getters["auth/getLoginToken"];
  if (loginToken) {
    //Token is available, user might still be logged in
    try {
      //fetch user data for the first time and saves in the vuex store
      await store.dispatch("auth/getUserData");
    } catch (e) {
      store.dispatch("auth/logout"); //token must have expired
    }
  }

  let user = store.state.auth.user;
  if (user) {
    routes.push({
      path: "/",
      alias: "/home",
      name: "home",
      component: () => import(`./pages/home/home.vue`),
      props: true,
    });
  } else {
    routes.push({
      path: "/",
      alias: "/index",
      name: "index",
      component: () => import("./pages/index/index.vue"),
      props: true,
    });
  }

  const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) return savedPosition;
      return { x: 0, y: 0 };
    },
  });
  return router;
};
