<template>
  <div class="layout-wrapper layout-static layout-static-sidebar-inactive" @click="onWrapperClick">
    <template v-if="$isLoggedIn">
      <div class="layout-topbar bg-white">
        <div class="layout-topbar-menu flex-grow-1 justify-content-between">
          <div >
            <router-link to="/">
              <Button
                class="p-button-link p-0 font-bold text-black"
              >
                <img src="images/logo.png" alt="logo" style="width: 30px; border-radius: 50%; overflow: hidden" class="" /> 
                <span class="ml-2 font-bold"> GCTU APPLY</span>
              </Button>
            </router-link>
          </div>
          <div class="hidden-xs">
            <a target="_blank" href="https://site.gctu.edu.gh/">
              <Button
                :label="$t('Main Website')"
                class="p-button-sm p-button-link text-black px-4"
                icon="pi pi-link"
              ></Button>
            </a>
          </div>
          <div>
           <Button
            @click="startLogout()"
            :label="$t('logout')"
            class="p-button-sm p-button-danger px-4"
            icon="pi pi-window-minimize"
          ></Button>
          </div>

        </div>
      </div>
      <div class="layout-sidebar" style="background-color: #d6dbde;" @click="onSidebarClick">
        
        <AppMenu
          :model="navbarSideLeftItems"
          @menuitem-click="onMenuItemClick"
        />
      </div>
    </template>
    <template v-else>
      <div class="layout-topbar bg-primary text-white">
        <div class="layout-topbar-menu flex-grow-1 justify-content-between">
          <div>
            <router-link to="/">
              <Button
                class="p-button-link p-0 font-bold text-white"
              >
                <img src="images/logo.png" alt="logo" style="width: 30px; border-radius: 50%; overflow: hidden" class="" /> 
                <span class="ml-2 font-bold"> GCTU APPLY</span>
              </Button>
            </router-link>
          </div>
          <div>
            <a target="_blank" href="https://site.gctu.edu.gh/">
              <Button
                :label="$t('Main Website')"
                class="p-button-sm p-button-link text-white px-4"
                icon="pi pi-link"
              ></Button>
            </a>
          </div>
        </div>
      </div>
    </template>
    <div
      class="layout-main-container bg-repeat-y bg-repeat-x bg-full"
      :style="
        !$isLoggedIn
          ? `background-image:linear-gradient(357deg, rgb(255, 254, 254), rgb(255 255 255 / 55%)), url('${dottedBg}'); background-size: cover;`
          : ``
      "
    >
      <div class="layout-main">
        <router-view />
      </div>
      <div class="layout-footer justify-content-between align-items-center">
        <div class="mr-2">
          <router-link to="/" class="flex align-items-center">
            <img src="images/logo.png" alt="logo" style="height: 26px" />
            <span class="hidden-xs font-small text-sm text-500 ml-2 text-md"
              >© {{ new Date().getFullYear() }} GCTU APPLY.
              {{ $t("all_rights_reserved") }}
            </span>
          </router-link>
        </div>
        <div>
          <lang-switcher></lang-switcher>
        </div>
      </div>
    </div>
    <!-- App right drawer -->
    <Sidebar
      position="right"
      class="p-sidebar-md"
      v-model:visible="rightDrawer"
      :width="rightDrawerWidth"
    >
      <component
        v-if="rightDrawer"
        is-sub-page
        :is="componentFile"
        :api-path="pageUrl"
        :model-bind="modelBind"
        :page-data="pageData"
      ></component>
    </Sidebar>
    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>
    <!-- Page display dialog -->
    <Dialog
      :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
      :style="{ width: dialogProps.width, maxWidth: dialogProps.maxWidth }"
      :modal="!dialogProps.seamless"
      :maximized="dialogProps.maximized"
      :dismissableMask="!dialogProps.persistent"
      :position="dialogProps.position"
      v-model:visible="pageDialog"
      :showHeader="true"
    >
      <template #header>
        <span></span>
      </template>
      <template #footer>
        <span></span>
      </template>
      <div class="">
        <component
          v-if="pageDialog"
          is-sub-page
          :is="componentFile"
          :api-path="pageUrl"
          :model-bind="modelBind"
          :page-data="pageData"
        ></component>
      </div>
    </Dialog>
    <!-- image preview dialog-->
    <Dialog
      header="&nbsp;"
      :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
      style="width: 50vw"
      v-model:visible="imageViewerDialog"
      dismissableMask
      modal
    >
      <Carousel
        :value="imageViewerImages"
        :circular="false"
        :page="imageViewerCurrentSlide"
      >
        <template #item="{ data }">
          <img style="max-width: 100%; max-height: 100%" :src="data" />
        </template>
      </Carousel>
    </Dialog>
        <!-- pdf preview dialog-->
        <Dialog
      header="&nbsp;"
      style="width: 50vw"
      v-model:visible="pdfViewerDialog"
      dismissableMask
      modal
    >
      <div>
        <div class="text-center mb-5">
          <Button class="p-button-primary p-button-outlined" icon="pi pi-refresh"
          label="Rotate Document" v-if="!pdfViewerDialogMessage" @click="pdfViewerRotation()" />
        </div>
        <div class="text-center text-2xl my-5" v-if="pdfViewerDialogMessage">
          {{ pdfViewerDialogMessage }}
        </div>
        <vue-pdf-embed
          :source="pdfViewerFile"
          :rotation="pdfViewerDialogRotate"
          @loading-failed="pdfViewerMessage('Unable to load document')"
          @loaded="pdfViewerMessage('Loading document ... 0%')"
          @rendering-failed="pdfViewerMessage('Unable to load document')"
          @rendered="pdfViewerMessage('')"
          @progress="pdfViewerMessage('Loading document ... 50%')"
        />
        <div class="text-center my-3">
          <a
            style="text-decoration: none"
            class="btn font-bold text-green-500"
            target="_blank"
            :href="$utils.setFileUrl(pdfViewerFile)"
            >Click to download document
          </a>
        </div>
      </div>
    </Dialog>
    <!-- request error dialog -->
    <Dialog
      modal
      v-model:visible="errorDialog"
      :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
      style="width: 30vw"
      position="bottom"
    >
      <template #header>
        <div class="flex align-items-center">
          <div class="mr-2">
            <Avatar
              size="large"
              class="bg-pink-50 text-pink-600"
              icon="pi pi-exclamation-triangle"
            />
          </div>
          <div class="flex text-md text-pink-600 font-bold">
            <!-- {{ $t("unable_to_complete_request") }} -->
            Ooops!
          </div>
        </div>
      </template>
      <div class="">
        <div
          class="text-grey-500 text-md"
          v-for="(msg, index) in pageErrors"
          :key="index"
        >
          <div class="card text-pink-600">{{ msg }}</div>
        </div>
      </div>
    </Dialog>
    <ConfirmDialog
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
    ></ConfirmDialog>
    <Toast position="top-center" />
  </div>
</template>
<script>
import VuePdfEmbed from "vue-pdf-embed";
import { defineAsyncComponent, defineComponent, ref, computed } from "vue";
import AppMenu from "./components/AppMenu.vue";
import { mapMutations, mapState, mapActions } from "vuex";
import dottedBg from "@/assets/images/auth-bg.png";
export default {
  name: "App",
  components: {
    AppMenu: AppMenu, VuePdfEmbed,
  },
  data: () => ({
    layoutMode: "static",
    staticMenuInactive: false,
    overlayMenuActive: false,
    mobileMenuActive: false,
    dottedBg,
    pdfViewerDialogMessage: "Loading",
    pdfViewerDialogRotate: 0,
  }),
  computed: {
    ...mapState("pageComponents", [
      "pageComponent",
      "pageUrl",
      "pageData",
      "modelBind",
      "imageViewerImages",
      "pdfViewerFile",
      "dialogProps",
    ]),
    appBarActionMenu() {
      return [
        {
          to: "/account",
          label: this.$t("my_account"),
          icon: "pi pi-user",
        },
        {
          label: this.$t("logout"),
          icon: "pi pi-window-minimize",
          command: (event) => {
            this.startLogout();
          },
        },
      ];
    },
    pageDialog: {
      get: function () {
        return this.$store.state.pageComponents.pageDialog;
      },
      set: function (value) {
        this.$store.commit("pageComponents/setPageDialog", value);
      },
    },
    errorDialog: {
      get: function () {
        return this.pageErrors.length > 0;
      },
      set: function (newValue) {
        this.pageErrors = [];
      },
    },
    imageViewerCurrentSlide: {
      get: function () {
        return this.$store.state.pageComponents.imageViewerCurrentSlide;
      },
      set: function (value) {
        this.$store.commit("pageComponents/setImageViewerCurrentSlide", value);
      },
    },
    imageViewerDialog: {
      get: function () {
        return this.$store.state.pageComponents.imageViewerDialog;
      },
      set: function (value) {
        this.$store.commit("pageComponents/setImageViewerDialog", value);
      },
    },
    pdfViewerDialog: {
      get: function () {
        return this.$store.state.pageComponents.pdfViewerDialog;
      },
      set: function (value) {
        this.$store.commit("pageComponents/setPdfViewerDialog", value);
      },
    },
    rightDrawer: {
      get: function () {
        return this.$store.state.pageComponents.rightDrawer;
      },
      set: function (value) {
        this.$store.commit("pageComponents/setRightDrawer", value);
      },
    },
    rightDrawerWidth: function () {
      if (this.$platform.isDesktop) {
        return this.$store.state.pageComponents.rightDrawerWidth;
      }
      return 320;
    },
    pageErrors: {
      get: function () {
        return this.$store.state.pageComponents.pageErrors;
      },
      set: function (value) {
        this.$store.commit("pageComponents/setPageErrors", value);
      },
    },
    componentFile() {
      if (this.pageComponent) {
        return defineAsyncComponent(() =>
          import(`./pages/${this.pageComponent}.vue`)
        );
      }
      return null;
    },
    containerClass() {
      if (!this.$isLoggedIn) {
        this.staticMenuInactive = true;
        this.mobileMenuActive = false;
      }
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": false,
        },
      ];
    },
    navbarSideLeftItems() {
      return this.$menus.navbarSideLeftItems;
    },
    navbarTopLeftItems() {
      return this.$menus.navbarTopLeftItems;
    },
    navbarTopRightItems() {
      return this.$menus.navbarTopRightItems;
    },
  },
  created: function () {
    // Add a request interceptor
    this.$api.axios().interceptors.request.use(
      (config) => {
        this.$store.commit("pageComponents/setPageErrors", []);
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    this.$api.axios().interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.request.status == 401) {
          if (this.$route.name != "index") {
            this.startLogout();
            return;
          }
        } else if (error.request.status == 403) {
          this.$router.push("/error/forbidden");
          return;
        }
        // reject error. Error will be handle by calling page.
        throw error;
      }
    );
  },
  watch: {
    $route(to, from) {
      //clear any errors from previous page
      this.$store.commit("pageComponents/setPageErrors", []);

      //close dialogs open from previous page
      this.$store.commit("pageComponents/setRightDrawer", false);
      this.$store.commit("pageComponents/setPageDialog", false);
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    pdfViewerMessage(message) {
      return (this.pdfViewerDialogMessage = message);
    },
    pdfViewerRotation(number) {
      if(this.pdfViewerDialogRotate >= 270){
        return this.pdfViewerDialogRotate = 0;
      }
      return (this.pdfViewerDialogRotate = this.pdfViewerDialogRotate + 90);
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;
      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }
    },
    toggleUserMenuPanel(event) {
      this.$refs.menuuserpanel.toggle(event);
    },
    startLogout() {
      this.logout();
      window.location = "/";
      //this.$router.go("/"); //force reload of the page
    },
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
