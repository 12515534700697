
import { utils } from '../utils';


const publicPages = ['/', '/index', '/error']; //public pages which do not need authentation
const excludedRoutes = []; //all pages which do not need authentation
const roleAbilities = {
  "administrator": [],
  "user": []
};

export default async({ app, store, router }) => {
	let user = null;
	let userRoleNames = [];
	let userPages = [];
	let isLoggedIn = false;
	user = store.state.auth.user;
	userRoleNames = store.state.auth.userRoles;
	userPages = store.state.auth.userPages;
	if(user){ //token is still valid
		app.config.globalProperties.$User = user;
		app.config.globalProperties.$UserPages = userPages;
		app.config.globalProperties.$UserRoleNames = userRoleNames;
		app.config.globalProperties.$UserName = user.account_username;
		app.config.globalProperties.$UserID = user.applicant_id;
		app.config.globalProperties.$UserEmail = user.account_email;
		app.config.globalProperties.$UserTelephone = null;
		app.config.globalProperties.$UserPhoto = user.account_photo;
		isLoggedIn = true;
	}
	app.config.globalProperties.$isLoggedIn = isLoggedIn;
	
	router.beforeEach((to, from, next) => {
		user = store.state.auth.user;
		
		let path = to.path;
		
		let pagePath = utils.getPagePath(path);
		let routePath = utils.getRoutePath(path);

		let authRequired = true;

		//check if page is excluded from authentication
		if(publicPages.includes(pagePath) || excludedRoutes.includes(routePath)){
			authRequired = false;
		}

		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/',  query: { nexturl: to.fullPath } });
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

		//continue to specified route
		next();
	});

}
