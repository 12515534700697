import { StorageService } from '../services/storage';
const currentLocale = StorageService.getLocale() || "en";
import locale  from '../i18n/index.js';
const messages = locale[currentLocale];
const i18n = {
  locale: currentLocale,
  messages,
  t: function (key){
    return messages[key] || key;
  }
};
export { i18n }