export default {
  "a_level": "A-LEVEL",
  "a_link_will_be_sent_to_your_email_containing_the_information_you_need_for_your_password": "A link will be sent to your email containing the information you need for your password",
  "a_message_has_been_sent_to_your_email__kindly_follow_the_link_to_reset_your_password": "A message has been sent to your email. Kindly follow the link to reset your password",
  "about_us": "About us",
  "academic_year": "Academic Year",
  "account_detail": "Account Detail",
  "account_email": "Account Email",
  "account_name": "Name",
  "account_password": "Account Password",
  "account_photo": "Account Photo",
  "account_status": "Account Status",
  "account_type": "Account Type",
  "account_username": "Account Username",
  "action": "Action",

  "add_new__tabledisplaylabel": "Add New {TableDisplayLabel}",
  "add_new_administrators": "Add New Administrators",
  "add_new_app_config": "Add New App Config",
  "add_new_applicant_selected_grants": "Add New Applicant Selected Grants",
  "add_new_applicants": "Add New Applicants",
  "add_new_audit_for_applicants": "Add New Audit For Applicants",
  "add_new_institution_accounts": "Add New Institution Accounts",
  "add_new_institution_admissions_uploads": "Add New Institution Admissions Uploads",
  "add_new_institution_campuses": "Add New Institution Campuses",
  "add_new_institution_programmes": "Add New Institution Programmes",
  "add_new_institution_schools": "Add New Institution Schools",
  "add_new_payments": "Add New Payments",
  "add_new_third_party_agencies": "Add New Third Party Agencies",
  "add_new_third_party_agencies_grants": "Add New Third Party Agencies Grants",
  "additional_form_definition": "Additional Form Definition",
  "address_line_1": "Address Line 1",
  "address_line_2": "Address Line 2",
  "address_line_3": "Address Line 3",
  "address_line_4": "Address Line 4",
  "address_line1": "Address Line1",
  "address_line2": "Address Line2",
  "address_line3": "Address Line3",
  "address_line4": "Address Line4",
  "administrators": "Administrators",
  "administrators_details": "Administrators Details",
  "agency_name": "Agency Name",
  "alive": "Alive",
  "all_rights_reserved": "All Rights Reserved",
  "allow_email_alert": "Allow Email Alert",
  "allow_sms_alert": "Allow Sms Alert",
  "allow_two_factor_auth": "Two Factor Auth",
  "already_exist": " Already exist!",
  "already_have_an_account": "Already have an account?",
  "amount": "Amount",

  "app_config": "App Config",
  "app_config_details": "App Config Details",
  "app_config_id": "App Config Id",
  "applicant_accepted_programme_id": "Applicant Accepted Programme Id",
  "applicant_id": "Applicant Id",
  "applicant_selected_grant_id": "Applicant Selected Grant Id",
  "applicant_selected_grants": "Applicant Selected Grants",
  "applicant_selected_grants_details": "Applicant Selected Grants Details",
  "applicants": "Applicants",
  "applicants_detail": "Applicants Detail",
  "applicants_details": "Applicants Details",
  "application_form_fee": "Application Form Fee",
  "application_ref": "Application Ref",
  "application_status": "Application Status",
  "april": "April",
  "are_you_employed": "Are you Employed?",
  "are_you_sure_you_want_to_delete_these_records": "Are you sure you want to delete these records?",
  "are_you_sure_you_want_to_delete_this_record": "Are you sure you want to delete this record?",
  "athletics": "Athletics",
  "attended_shs": "Attended SHS",
  "audit_for_applicants": "Audit For Applicants",
  "audit_for_applicants_details": "Audit For Applicants Details",
  "august": "August",
  "available": "Available",
  "awaiting_results": "Awaiting Results?",
  "baccalaureate": "BACCALAUREATE",
  "back": "Back",
  "badminton": "Badminton",
  "baseball": "Baseball",

  "basketball": "Basketball",
  "browse": "Browse...",
  "business": "Business",
  "campus_description": "Campus Description",
  "campus_name": "Campus Name",
  "cancel": "Cancel",
  "capital_letter": "Capital Letter",
  "certificate_url": "Certificate Url",
  "change_email": "Change Email",
  "change_password": "Change Password",
  "characters_minimum": "Characters minimum",
  "checking_availability": "Checking availability ...",
  "chess": "Chess",
  "choose_files_or_drop_files_here": "Choose file or drop file here",
  "click_here_to_login": "Click here to login",
  "client_request_id": "Client Request Id",
  "close": "Close",
  "completed": "Completed",
  "confirm_new_password": "Confirm new password",
  "confirm_password": "Confirm Password",
  "congratulations": "Congratulations!",
  "contact_us": "Contact us",
  "continue": "Continue",
  "country": "Country",
  "country_of_origin": "Country of Origin",
  "cricket": "Cricket",
  "cross_country": "Cross Country",
  "current_password": "Current Password",
  "cut_off_point": "Cut Off Point",
  "data_imported_successfully": "Data imported successfully",
  "date_created": "Date Created",
  "date_updated": "Date Updated",
  "day_of_birth": "Day of Birth",
  "deceased": "Deceased",

  "december": "December",
  "degree_awarded": "Degree Awarded",
  "degree_class": "Degree Class",
  "delete": "Delete",
  "delete_account": "Delete Account",
  "delete_record": "Delete record",
  "delete_selected": "Delete Selected",
  "delete_this_record": "Delete this record",
  "description": "Description",
  "dial_code": "Code",
  "did_you_attend_senior_high_school": "Did You Attend Senior High School?",
  "digital_address": "Digital Address",
  "disability": "Disability",
  "disability_other": "Disability Other",
  "distinction": "Distinction",
  "divorced": "Divorced",
  "do_you_do_any_sports": "Do you do any sports?",
  "do_you_have_any_disability": "Do you have any disability?",
  "dob_day": "Dob Day",
  "dob_month": "Dob Month",
  "dob_year": "Dob Year",
  "dont_have_an_account": "Don't Have an Account?",
  "drivers_license": "Drivers License",
  "edit": "Edit",
  "edit__tabledisplaylabel": "Edit {TableDisplayLabel}",
  "edit_account": "Edit Account",
  "edit_administrators": "Edit Administrators",
  "edit_app_config": "Edit App Config",
  "edit_applicant_selected_grants": "Edit Applicant Selected Grants",
  "edit_applicants": "Edit Applicants",
  "edit_institution_accounts": "Edit Institution Accounts",
  "edit_institution_admissions_uploads": "Edit Institution Admissions Uploads",
  "edit_institution_campuses": "Edit Institution Campuses",

  "edit_institution_programmes": "Edit Institution Programmes",
  "edit_institution_schools": "Edit Institution Schools",
  "edit_my_account": "Edit my account",
  "edit_payments": "Edit Payments",
  "edit_third_party_agencies": "Edit Third Party Agencies",
  "edit_third_party_agencies_grants": "Edit Third Party Agencies Grants",
  "edit_this_record": "Edit This Record",
  "email": "Email",
  "email_address": "Email Address",
  "email_address_changed_successfully": "Email address changed successfully",
  "email_address_is_already_verified": "Email address is already verified",
  "email_address_is_not_registered": "Email address is not registered",
  "email_not_changed": "Email not changed",
  "email_or_phone_number": "Email or Phone Number",
  "email_verification_completed": "Email verification completed.",
  "email_verification_has_been_resent": "Email verification has been resent",
  "email_verification_link_sent": "Email verification link sent",
  "email_verified_at": "Email Verified At",
  "employment_address": "Employment Address",
  "employment_nature": "Employment Nature",
  "employment_nature_other": "Employment Nature Other",
  "enter__fielddisplaylabel": "Enter {FieldDisplayLabel}",
  "enter_account_email": "Enter Account Email",
  "enter_account_name": "Enter Name",
  "enter_account_password": "Enter Account Password",
  "enter_account_status": "Enter Account Status",
  "enter_account_type": "Enter Account Type",
  "enter_account_username": "Enter Account Username",
  "enter_additional_form_definition": "Enter Additional Form Definition",
  "enter_address_line_3": "Enter Address Line 3",
  "enter_address_line1": "Enter Address Line1",
  "enter_address_line2": "Enter Address Line2",
  "enter_address_line3": "Enter Address Line3",
  "enter_address_line4": "Enter Address Line4",
  "enter_agency_name": "Enter Agency Name",
  "enter_allow_email_alert": "Enter Allow Email Alert",
  "enter_allow_sms_alert": "Enter Allow Sms Alert",
  "enter_allow_two_factor_auth": "Enter Allow Two Factor Auth",
  "enter_amount": "Enter Amount",
  "enter_applicant_accepted_programme_id": "Enter Applicant Accepted Programme Id",
  "enter_application_ref": "Enter Application Ref",
  "enter_application_status": "Enter Application Status",
  "enter_attended_shs": "Enter Attended SHS",

  "enter_awaiting_results": "Enter Awaiting Results?",
  "enter_campus_description": "Enter Campus Description",
  "enter_campus_name": "Enter Campus Name",
  "enter_certificate_url": "Enter Certificate Url",
  "enter_client_request_id": "Enter Client Request Id",
  "enter_country": "Enter Country",
  "enter_cut_off_point": "Enter Cut Off Point",
  "enter_description": "Enter Description",
  "enter_dial_code": "Enter Code",
  "enter_did_you_attend_senior_high_school": "Enter Did You Attend Senior High School?",
  "enter_digital_address": "Enter Digital Address",
  "enter_disability": "Enter Disability",
  "enter_disability_other": "Enter Disability Other",
  "enter_dob_day": "Enter Dob Day",
  "enter_dob_month": "Enter Dob Month",
  "enter_dob_year": "Enter Dob Year",
  "enter_email": "Enter Email",
  "enter_email_address": "Enter Email Address",
  "enter_employment_address": "Enter Employment Address",
  "enter_employment_nature": "Enter Employment Nature",
  "enter_employment_nature_other": "Enter Employment Nature Other",
  "enter_entry_level": "Enter Entry Level",
  "enter_entry_level_scenario": "Enter Entry Level Scenario",
  "enter_exams_sittings": "Enter Exams Sittings",
  "enter_fathers_email": "Enter Father's Email",
  "enter_fathers_living_status": "Enter Father's Living Status",
  "enter_fathers_name": "Enter Father's Name",
  "enter_fathers_occupation": "Enter Father's Occupation",
  "enter_fathers_occupation_other": "Enter Father's Occupation Other",
  "enter_fathers_telephone": "Enter Father's Telephone",
  "enter_first_institution_acceptance": "Enter First Institution Acceptance",
  "enter_first_institution_first_programme_admission_status": "Enter First Institution First Programme Admission Status",
  "enter_first_institution_first_programme_id": "Enter First Institution First Programme Id",
  "enter_first_institution_first_programme_options": "Enter First Institution First Programme Options",
  "enter_first_institution_first_programme_required_electives": "Enter First Institution First Programme Required Electives",
  "enter_first_institution_first_programme_session": "Enter First Institution First Programme Session",
  "enter_first_institution_id": "Enter First Institution Id",
  "enter_first_institution_second_programme_admission_status": "Enter First Institution Second Programme Admission Status",
  "enter_first_institution_second_programme_id": "Enter First Institution Second Programme Id",
  "enter_first_institution_second_programme_options": "Enter First Institution Second Programme Options",
  "enter_first_institution_second_programme_required_electives": "Enter First Institution Second Programme Required Electives",
  "enter_first_institution_second_programme_session": "Enter First Institution Second Programme Session",
  "enter_first_institution_third_programme_admission_status": "Enter First Institution Third Programme Admission Status",
  "enter_first_institution_third_programme_id": "Enter First Institution Third Programme Id",
  "enter_first_institution_third_programme_options": "Enter First Institution Third Programme Options",
  "enter_first_institution_third_programme_required_electives": "Enter First Institution Third Programme Required Electives",
  "enter_first_institution_third_programme_session": "Enter First Institution Third Programme Session",
  "enter_first_programme_options": "Enter First Programme Options",
  "enter_firstname": "Enter Firstname",
  "enter_gender": "Enter Gender",

  "enter_grant_additional_info": "Enter Grant Additional Info",
  "enter_grant_description": "Enter Grant Description",
  "enter_grant_name": "Enter Grant Name",
  "enter_grant_type": "Enter Grant Type",
  "enter_guardian_address1": "Enter Guardian Address1",
  "enter_guardian_address2": "Enter Guardian Address2",
  "enter_guardian_address3": "Enter Guardian Address3",
  "enter_guardian_email": "Enter Guardian Email",
  "enter_guardian_name": "Enter Guardian Name",
  "enter_guardian_occupation": "Enter Guardian Occupation",
  "enter_guardian_occupation_other": "Enter Guardian Occupation Other",
  "enter_guardian_telephone": "Enter Guardian Telephone",
  "enter_has_attended_tertiary": "Enter Has Attended Tertiary",
  "enter_has_vacancy": "Enter Has Vacancy",
  "enter_have_you_attended_any_tertiary_institution": "Enter Have You Attended Any Tertiary Institution?",
  "enter_hometown": "Enter Hometown",
  "enter_hometown_region": "Enter Hometown Region",
  "enter_id_number": "Enter ID Number",
  "enter_in_case_you_are_not_offered_admission_would_you_like_the_selected_institution_to_pass_details_of_your_application_to_affiliated_private_universities": "Enter In case you are not offered admission would you like the selected institution to pass details of your application to other private Universities?",
  "enter_in_case_you_dont_meet_the_regular_cut_off_point_would_you_like_to_be_considered_for_fee_paying": "Enter In case you don't meet the regular cut off point,would you like to be considered for Fee-Paying?",
  "enter_index_number": "Enter Index Number",
  "enter_institution_additional_info": "Enter Institution Additional Info",
  "enter_institution_school_id": "Enter Institution School Id",
  "enter_is_athlete": "Enter Is Athlete",
  "enter_is_awaiting_results": "Enter Is Awaiting Results",
  "enter_is_disable": "Enter Is Disable",
  "enter_is_employed": "Enter Is Employed",
  "enter_is_fee_paying": "Enter Is Fee Paying",
  "enter_is_grant_available": "Enter Is Grant Available",
  "enter_last_competition": "Enter Last Competition",
  "enter_last_shs_attended": "Enter Last SHS Attended",
  "enter_last_shs_other": "Enter Last SHS Other",
  "enter_last_shs_outside": "Enter Last SHS Outside",
  "enter_last_tertiary_attended": "Enter Last Tertiary Attended",
  "enter_lastname": "Enter Lastname",
  "enter_marital_status": "Enter Marital Status",
  "enter_mothers_email": "Enter Mother's Email",
  "enter_mothers_living_status": "Enter Mother's Living Status",
  "enter_mothers_name": "Enter Mother's Name",
  "enter_mothers_occupation": "Enter Mother's Occupation",
  "enter_mothers_occupation_other": "Enter Mother's Occupation Other",
  "enter_mothers_telephone": "Enter Mother's Telephone",
  "enter_name": "Enter Name",
  "enter_national_id_number": "Enter National ID Number",
  "enter_national_id_type": "Enter National ID Type",
  "enter_othernames": "Enter Othernames",
  "enter_otp": "Enter OTP",

  "enter_pass_to_affiliate": "Enter Pass To Affiliate",
  "enter_password": "Enter Password",
  "enter_payment_status": "Enter Payment Status",
  "enter_ph_address_line1": "Enter Ph Address Line1",
  "enter_ph_address_line2": "Enter Ph Address Line2",
  "enter_ph_address_line3": "Enter Ph Address Line3",
  "enter_ph_address_line4": "Enter Ph Address Line4",
  "enter_primary_telephone": "Enter Primary Telephone",
  "enter_programme_description": "Enter Programme Description",
  "enter_programme_options": "Enter Programme Options",
  "enter_programme_required_electives": "Enter Programme Required Electives",
  "enter_programme_title": "Enter Programme Title",
  "enter_religious_denomination": "Enter Religious Affiliation",
  "enter_religious_denomination_other": "Enter Religious Affiliation Other",
  "enter_same_address_as_ph_address": "Enter Same Address As Ph Address",
  "enter_same_as_address_above": "Enter Same as address above",
  "enter_same_as_personal_information": "Enter Same as personal information",
  "enter_same_as_personal_information_above": "Enter Same as personal information above",
  "enter_same_bio_as_sponsor": "Enter Same Bio As Sponsor",
  "enter_scanned_national_id_card": "Enter Scanned National Id Card",
  "enter_second_institution_acceptance": "Enter Second Institution Acceptance",
  "enter_second_institution_first_programme_admission_status": "Enter Second Institution First Programme Admission Status",
  "enter_second_institution_first_programme_id": "Enter Second Institution First Programme Id",
  "enter_second_institution_first_programme_options": "Enter Second Institution First Programme Options",
  "enter_second_institution_first_programme_required_electives": "Enter Second Institution First Programme Required Electives",
  "enter_second_institution_first_programme_session": "Enter Second Institution First Programme Session",
  "enter_second_institution_id": "Enter Second Institution Id",
  "enter_second_institution_second_programme_admission_status": "Enter Second Institution Second Programme Admission Status",
  "enter_second_institution_second_programme_id": "Enter Second Institution Second Programme Id",
  "enter_second_institution_second_programme_options": "Enter Second Institution Second Programme Options",
  "enter_second_institution_second_programme_required_electives": "Enter Second Institution Second Programme Required Electives",
  "enter_second_institution_second_programme_session": "Enter Second Institution Second Programme Session",
  "enter_second_institution_third_programme_admission_status": "Enter Second Institution Third Programme Admission Status",
  "enter_second_institution_third_programme_id": "Enter Second Institution Third Programme Id",
  "enter_second_institution_third_programme_options": "Enter Second Institution Third Programme Options",
  "enter_second_institution_third_programme_required_electives": "Enter Second Institution Third Programme Required Electives",
  "enter_second_institution_third_programme_session": "Enter Second Institution Third Programme Session",
  "enter_second_programme_options": "Enter Second Programme Options",
  "enter_shs_course": "Enter SHS Course",
  "enter_shs_exams_results": "Enter SHS Exams Results",
  "enter_shs_from_year": "Enter SHS From Year",
  "enter_shs_grade": "Enter SHS Grade",
  "enter_shs_qualification": "Enter SHS Qualification",
  "enter_shs_to_year": "Enter SHS To Year",
  "enter_sponsor_address": "Enter Sponsor Address",
  "enter_sponsor_email": "Enter Sponsor Email",
  "enter_sponsor_name": "Enter Sponsor Name",
  "enter_sponsor_telephone": "Enter Sponsor Telephone",
  "enter_sports_rep_institution": "Enter Sports Rep Institution",
  "enter_street_address": "Enter Street Address",
  "enter_telephone": "Enter Telephone",
  "enter_tertiary_degree_awarded": "Enter Tertiary Degree Awarded",
  "enter_tertiary_degree_class": "Enter Tertiary Degree Class",
  "enter_tertiary_from_year": "Enter Tertiary From Year",
  "enter_tertiary_programme": "Enter Tertiary Programme",
  "enter_tertiary_reason_for_leaving": "Enter Tertiary Reason For Leaving",
  "enter_tertiary_student_id": "Enter Tertiary Student Id",
  "enter_tertiary_to_year": "Enter Tertiary To Year",
  "enter_the_code": "Enter the code",
  "enter_third_institution_acceptance": "Enter Third Institution Acceptance",
  "enter_third_institution_first_programme_admission_status": "Enter Third Institution First Programme Admission Status",
  "enter_third_institution_first_programme_id": "Enter Third Institution First Programme Id",
  "enter_third_institution_first_programme_options": "Enter Third Institution First Programme Options",
  "enter_third_institution_first_programme_required_electives": "Enter Third Institution First Programme Required Electives",
  "enter_third_institution_first_programme_session": "Enter Third Institution First Programme Session",
  "enter_third_institution_id": "Enter Third Institution Id",
  "enter_third_institution_second_programme_admission_status": "Enter Third Institution Second Programme Admission Status",
  "enter_third_institution_second_programme_id": "Enter Third Institution Second Programme Id",
  "enter_third_institution_second_programme_options": "Enter Third Institution Second Programme Options",
  "enter_third_institution_second_programme_required_electives": "Enter Third Institution Second Programme Required Electives",
  "enter_third_institution_second_programme_session": "Enter Third Institution Second Programme Session",
  "enter_third_institution_third_programme_admission_status": "Enter Third Institution Third Programme Admission Status",
  "enter_third_institution_third_programme_id": "Enter Third Institution Third Programme Id",
  "enter_third_institution_third_programme_options": "Enter Third Institution Third Programme Options",
  "enter_third_institution_third_programme_required_electives": "Enter Third Institution Third Programme Required Electives",
  "enter_third_institution_third_programme_session": "Enter Third Institution Third Programme Session",
  "enter_third_programme_options": "Enter Third Programme Options",
  "enter_title": "Enter Title",

  "enter_transaction_id": "Enter Transaction ID",
  "enter_txt_journey": "Enter Txt Journey",
  "enter_username": "Enter Username",
  "entry_level": "Entry Level",
  "entry_level_scenario": "Entry Level Scenario (optional)",
  "error_deleting_the_record__please_make_sure_that_the_record_exit": "Error deleting the record. please make sure that the record exit",
  "error_inserting_record": "Error inserting record",
  "error_processing_request": "Error processing request!",
  "error_registering_user": "Error registering user",
  "error_sending_email__please_contact_system_administrator_for_more_info": "Error sending email. Please contact system administrator for more info",
  "error_uploading_file": "Error uploading file",
  "exams_sittings": "Exams Sittings",
  "existing_qualification": "Existing Qualification",
  "export": "Export",
  "export_csv": "Export CSV",
  "export_excel": "Export Excel",
  "export_page_fields": "Export Page Fields",
  "export_word": "Export Word",
  "fathers_email": "Father's Email",
  "fathers_living_status": "Father's Living Status",
  "fathers_name": "Father's Name",
  "fathers_occupation": "Father's Occupation",
  "fathers_occupation_other": "Father's Occupation Other",
  "fathers_telephone": "Father's Telephone",
  "february": "February",
  "female": "Female",
  "file_format_not_supported": "File format not supported",
  "first_choice_institution": "First Choice Institution",
  "first_choice_programme": "First Choice Programme",
  "first_class": "First Class",
  "first_institution_acceptance": "First Institution Acceptance",
  "first_institution_first_programme_admission_status": "First Institution First Programme Admission Status",
  "first_institution_first_programme_id": "First Institution First Programme Id",
  "first_institution_first_programme_options": "First Institution First Programme Options",
  "first_institution_first_programme_required_electives": "First Institution First Programme Required Electives",
  "first_institution_first_programme_session": "First Institution First Programme Session",
  "first_institution_id": "First Institution Id",
  "first_institution_second_programme_admission_status": "First Institution Second Programme Admission Status",
  "first_institution_second_programme_id": "First Institution Second Programme Id",
  "first_institution_second_programme_options": "First Institution Second Programme Options",
  "first_institution_second_programme_required_electives": "First Institution Second Programme Required Electives",
  "first_institution_second_programme_session": "First Institution Second Programme Session",
  "first_institution_third_programme_admission_status": "First Institution Third Programme Admission Status",
  "first_institution_third_programme_id": "First Institution Third Programme Id",
  "first_institution_third_programme_options": "First Institution Third Programme Options",
  "first_institution_third_programme_required_electives": "First Institution Third Programme Required Electives",
  "first_institution_third_programme_session": "First Institution Third Programme Session",
  "first_name": "First Name",
  "first_programme_options": "First Programme Options",
  "first_programme_session": "First Programme Session",
  "firstname": "Firstname",
  "football": "Football",
  "from": "From",
  "full_address_of_sponsor": "Full Address of Sponsor",
  "gender": "Gender",
  "general_arts": "General Arts",
  "ghana_card": "Ghana Card",
  "goalball": "Goalball",
  "grant_additional_info": "Grant Additional Info",
  "grant_description": "Grant Description",
  "grant_name": "Grant Name",
  "grant_type": "Grant Type",
  "guardian_address1": "Guardian Address1",
  "guardian_address2": "Guardian Address2",
  "guardian_address3": "Guardian Address3",
  "guardian_email": "Guardian Email",
  "guardian_name": "Guardian Name",
  "guardian_occupation": "Guardian Occupation",
  "guardian_occupation_other": "Guardian Occupation Other",
  "guardian_telephone": "Guardian Telephone",
  "guardians_address_line_1": "Guardian's Address Line 1",
  "guardians_address_line_2": "Guardian's Address Line 2",
  "guardians_address_line_3": "Guardian's Address Line 3",
  "guardians_email": "Guardian's Email",
  "guardians_name": "Guardian's Name",
  "guardians_occupation": "Guardian's Occupation",
  "guardians_telephone": "Guardian's Telephone",
  "handball": "Handball",
  "has_attended_tertiary": "Has Attended Tertiary",
  "has_vacancy": "Has Vacancy",
  "have_you_attended_any_tertiary_institution": "Have You Attended Any Tertiary Institution?",
  "help_and_faq": "Help and FAQ",
  "hi": "Hi",

  "hints___not_less_than_6_characters": "Hints : Not Less Than 6 Characters",
  "hockey": "Hockey",
  "home": "Home",
  "home_economics": "Home Economics",
  "home_town": "City / Province",
  "home_town_region": "Home Town Region / State",
  "hometown": "Home Town / City / Province",
  "hometown_region": "Hometown Region / State",
  "import_data": "Import Data",
  "in_case_you_are_not_offered_admission_would_you_like_the_selected_institution_to_pass_details_of_your_application_to_affiliated_private_universities": "In case you are not offered admission would you like the selected institution to pass details of your application to other private Universities?",
  "in_case_you_dont_meet_the_regular_cut_off_point_would_you_like_to_be_considered_for_fee_paying": "In case you don't meet the regular cut off point,would you like to be considered for Fee-Paying?",
  "index_number": "Index Number",
  "institution_accounts": "Institution Accounts",
  "institution_accounts_details": "Institution Accounts Details",
  "institution_additional_info": "Institution Additional Info",
  "institution_admissions_uploads": "Institution Admissions Uploads",
  "institution_admissions_uploads_details": "Institution Admissions Uploads Details",
  "institution_campus_id": "Institution Campus Id",
  "institution_campuses": "Institution Campuses",
  "institution_campuses_detail": "Institution Campuses Detail",
  "institution_campuses_details": "Institution Campuses Details",
  "institution_programme_id": "Institution Programme Id",
  "institution_programmes": "Institution Programmes",
  "institution_programmes_details": "Institution Programmes Details",
  "institution_school_id": "Institution School Id",
  "institution_schools": "Institution Schools",
  "institution_schools_details": "Institution Schools Details",
  "invalid_captcha_code": "Invalid captcha code",
  "invalid_request": "Invalid request",
  "is_athlete": "Is Athlete",
  "is_awaiting_results": "Is Awaiting Results",
  "is_disable": "Is Disable",
  "is_employed": "Is Employed",
  "is_fee_paying": "Is Fee Paying",
  "is_grant_available": "Is Grant Available",
  "january": "January",
  "july": "July",
  "june": "June",
  "language": "Language",
  "last_competition": "Last Competition",
  "last_name": "Last Name (Surname)",
  "last_shs_attended": "Last SHS Attended",
  "last_shs_other": "Last SHS Other",
  "last_shs_outside": "Last SHS Outside",
  "last_tertiary_attended": "Last Tertiary Attended",
  "lastname": "Lastname",
  "limit": "Limit",
  "load_more": "Load More",
  "loading": "Loading...",
  "log_id": "Log Id",
  "login": "Login",
  "login_completed": "Login completed",
  "logout": "Logout",
  "male": "Male",
  "march": "March",
  "marital_status": "Marital Status",
  "married": "Married",
  "may": "May",
  "month_of_birth": "Month of Birth",
  "mothers_email": "Mother's Email",
  "mothers_living_status": "Mother's Living Status",
  "mothers_name": "Mother's Name",
  "mothers_occupation": "Mother's Occupation",
  "mothers_occupation_other": "Mother's Occupation Other",
  "mothers_telephone": "Mother's Telephone",
  "my_account": "My Account",
  "name": "Name",
  "name_and_address_of_employer": "Name and Address of Employer",
  "name_of_sponsor": "Name of Sponsor",
  "national_id_number": "National Id Number",
  "national_id_type": "National ID Type",
  "nature_of_employment": "Nature of Employment",
  "netball": "Netball",
  "new_password": "New Password",
  "new_values": "New Values",
  "no": "No",
  "no_file_selected_for_upload": "No file selected for upload",
  "no_more_content_to_load": "No more content to load",
  "no_record_found": "No Record Found",
  "no_record_inserted": "No record inserted",
  "no_record_updated": "No record updated",
  "not_available": "Not available",
  "november": "November",
  "number": "Number",
  "o_level": "O-LEVEL",
  "october": "October",
  "of": "of",
  "old_values": "Old Values",
  "other": "Other",
  "other_caps": "OTHER",
  "other_names": "Other Names (Middle Names)",
  "othernames": "Othernames",
  "otp_has_been_sent_to_your_email": "OTP has been sent to your email",
  "otp_has_been_sent_to_your_phone_number_via_sms": "OTP has been sent to your phone number via SMS",
  "otp_verification": "OTP Verification",
  "page": "Page",
  "pass": "Pass",
  "pass_to_affiliate": "Pass To Affiliate",
  "passport": "Passport",
  "passport_picture": "Passport Picture",
  "password": "Password",
  "password_reset": "Password Reset ",
  "password_reset_key_failure": "Password reset key failure",
  "payment_id": "Payment Id",
  "payment_status": "Payment Status",
  "payments": "Payments",

  "payments_details": "Payments Details",
  "ph_address_line1": "Ph Address Line1",
  "ph_address_line2": "Ph Address Line2",
  "ph_address_line3": "Ph Address Line3",
  "ph_address_line4": "Ph Address Line4",
  "phone_number": "Phone Number",
  "photo": "Photo",
  "please_a_choose_file": "Please a choose file",
  "please_contact_the_system_administrator_for_more_information": "Please contact the system administrator for more information",
  "please_enter_text": "Please enter text",
  "please_provide_the_valid_email_address_you_used_to_register": "Please provide the valid email address you used to register",
  "please_verify_your_email_address_by_following_the_link_in_your_mailbox": "Please verify your email address by following the link in your mailbox",
  "please_you_can_try_reseting_your_password_by_following_these_steps": "Please you can try reseting your password by following these steps ",
  "primary_telephone": "Primary Telephone",
  "print": "Print",
  "print___pdf": "Print | PDF",
  "print_page_settings": "Print Page Settings",
  "privacy_policy": "Privacy Policy",
  "programme_description": "Programme Description",
  "programme_options": "Programme Options",
  "programme_required_electives": "Programme Required Electives",
  "programme_title": "Programme Title",
  "provide_new_password": "Provide new password",
  "record_added_successfully": "Record added successfully",
  "record_deleted_successfully": "Record deleted successfully",
  "record_id": "Record Id",
  "record_updated_successfully": "Record updated successfully",
  "records": "Records",
  "register": "Register Now",
  "religious_denomination": "Religious Affiliation",
  "religious_denomination_other": "Religious Affiliation Other",
  "remember_me": "Remember Me",
  "remove_page_links": "Remove Page Links",
  "request_url": "Request Url",
  "resend_email": "Resend Email",
  "reset_password": "Reset Password",
  "rugby": "Rugby",
  "same_address_as_ph_address": "Same Address As Ph Address",
  "same_as_address_above": "Same as address above",
  "same_as_personal_information": "Same as personal information",
  "same_as_personal_information_above": "Same as personal information above",
  "same_bio_as_sponsor": "Same Bio As Sponsor",
  "save_png": "Save PNG",
  "saving": "Saving...",
  "scanned_national_id_card": "Scanned National Id Card",
  "science": "Science",
  "search": "Search",
  "second_choice_institution": "Second Choice Institution",
  "second_choice_programme": "Second Choice Programme",
  "second_class_lower": "Second Class Lower",
  "second_class_upper": "Second Class Upper",
  "second_institution_acceptance": "Second Institution Acceptance",
  "second_institution_first_programme_admission_status": "Second Institution First Programme Admission Status",
  "second_institution_first_programme_id": "Second Institution First Programme Id",
  "second_institution_first_programme_options": "Second Institution First Programme Options",
  "second_institution_first_programme_required_electives": "Second Institution First Programme Required Electives",
  "second_institution_first_programme_session": "Second Institution First Programme Session",
  "second_institution_id": "Second Institution Id",
  "second_institution_second_programme_admission_status": "Second Institution Second Programme Admission Status",
  "second_institution_second_programme_id": "Second Institution Second Programme Id",
  "second_institution_second_programme_options": "Second Institution Second Programme Options",
  "second_institution_second_programme_required_electives": "Second Institution Second Programme Required Electives",
  "second_institution_second_programme_session": "Second Institution Second Programme Session",
  "second_institution_third_programme_admission_status": "Second Institution Third Programme Admission Status",
  "second_institution_third_programme_id": "Second Institution Third Programme Id",
  "second_institution_third_programme_options": "Second Institution Third Programme Options",
  "second_institution_third_programme_required_electives": "Second Institution Third Programme Required Electives",
  "second_institution_third_programme_session": "Second Institution Third Programme Session",
  "second_programme_options": "Second Programme Options",
  "second_programme_session": "Second Programme Session",
  "select": "Select ...",
  "select_a_file_to_import": "Select a file to import",
  "select_a_value": "Select a value ...",
  "select_first_choice_institution": "Select First Choice Institution ...",
  "select_first_choice_programme": "Select First Choice Programme ...",
  "select_guardians_occupation": "Select Guardian's Occupation ...",
  "select_paper_size": "Select Paper Size",
  "select_second_choice_institution": "Select Second Choice Institution ...",
  "select_second_choice_programme": "Select Second Choice Programme ...",
  "select_third_choice_institution": "Select Third Choice Institution ...",
  "select_third_choice_programme": "Select Third Choice Programme ...",
  "send": "Send",
  "separated": "Separated",
  "september": "September",
  "should_contain": "Should contain",
  "show_header": "Show Header",
  "shs__outside_ghana": "SHS (Outside Ghana)",
  "shs_certificate": "SHS Certificate",
  "shs_course": "SHS Course",
  "shs_exams_results": "SHS Exams Results",
  "shs_from_year": "SHS From Year",
  "shs_grade": "SHS Aggregate Score",
  "shs_qualification": "SHS Qualification",
  "shs_to_year": "SHS To Year",
  "single": "Single",
  "sort_by": "Sort By",
  "specify_disability": "Specify disability",
  "specify_all_other_disability": "Specify all other disabilities",
  "specify_discipline": "Specify Discipline",
  "specify_other": "Specify Other",
  "specify_senior_high_school__outside_ghana": "Specify Senior High School (Outside Ghana)",
  "sponsor_address": "Sponsor Address",
  "sponsor_email": "Sponsor Email",
  "sponsor_name": "Sponsor Name",
  "sponsor_telephone": "Sponsor Telephone",
  "sports_rep_institution": "Sports Rep Institution",
  "sssce": "SSSCE",
  "street_address": "Street Address",
  "student_id_number": "Student ID Number",
  "submit": "Submit",
  "supported_file_types": "Supported file types",
  "swimming": "Swimming",
  "symbol": "Symbol",
  "table_tennis": "Table Tennis",
  "tabledisplaylabel__details": "{TableDisplayLabel} Details",
  "technical": "Technical",
  "telephone": "Telephone",
  "terms_and_conditions": "Terms and Conditions",
  "tertiary_degree_awarded": "Tertiary Degree Awarded",
  "tertiary_degree_class": "Tertiary Degree Class",
  "tertiary_from_year": "Tertiary From Year",
  "tertiary_programme": "Tertiary Programme (Major)",
  "tertiary_reason_for_leaving": "Tertiary Reason For Leaving",
  "tertiary_student_id": "Tertiary Student Id",
  "tertiary_to_year": "Tertiary To Year",
  "the_dashboard": "The Dashboard",
  "the_email_address_is_not_registered_on_the_system": "The email address is not registered on the system",
  "the_email_verification_link_is_not_valid": "The email verification link is not valid",
  "third_choice_institution": "Third Choice Institution",
  "third_choice_programme": "Third Choice Programme",
  "third_class": "Third Class",
  "third_institution_acceptance": "Third Institution Acceptance",
  "third_institution_first_programme_admission_status": "Third Institution First Programme Admission Status",
  "third_institution_first_programme_id": "Third Institution First Programme Id",
  "third_institution_first_programme_options": "Third Institution First Programme Options",
  "third_institution_first_programme_required_electives": "Third Institution First Programme Required Electives",
  "third_institution_first_programme_session": "Third Institution First Programme Session",
  "third_institution_id": "Third Institution Id",
  "third_institution_second_programme_admission_status": "Third Institution Second Programme Admission Status",
  "third_institution_second_programme_id": "Third Institution Second Programme Id",
  "third_institution_second_programme_options": "Third Institution Second Programme Options",
  "third_institution_second_programme_required_electives": "Third Institution Second Programme Required Electives",
  "third_institution_second_programme_session": "Third Institution Second Programme Session",
  "third_institution_third_programme_admission_status": "Third Institution Third Programme Admission Status",
  "third_institution_third_programme_id": "Third Institution Third Programme Id",
  "third_institution_third_programme_options": "Third Institution Third Programme Options",
  "third_institution_third_programme_required_electives": "Third Institution Third Programme Required Electives",
  "third_institution_third_programme_session": "Third Institution Third Programme Session",
  "third_party_agencies": "Third Party Agencies",
  "third_party_agencies_detail": "Third Party Agencies Detail",
  "third_party_agencies_details": "Third Party Agencies Details",
  "third_party_agencies_grant_id": "Third Party Agencies Grant Id",
  "third_party_agencies_grants": "Third Party Agencies Grants",
  "third_party_agencies_grants_detail": "Third Party Agencies Grants Detail",
  "third_party_agencies_grants_details": "Third Party Agencies Grants Details",
  "third_party_agency_id": "Third Party Agency Id",
  "third_programme_options": "Third Programme Options",
  "third_programme_session": "Third Programme Session",
  "timestamp": "Timestamp",
  "title": "Title",
  "to": "To",
  "transaction_id": "Transaction ID",
  "txt_journey": "Txt Journey",
  "unable_to_complete_request": "Unable to complete request.",
  "update": "Update",
  "upload_a_copy_of_the_provided_national_id": "Upload A Photo/PDF of the provided national ID",
  "upload_passport_size_photograph": "Upload Passport Size Photograph",
  "user_agent": "User Agent",
  "user_id": "User Id",
  "user_ip": "User Ip",
  "user_login": "User Login",
  "user_registration": "User registration",
  "username": "Username",
  "username___email___phone_number": "Username / Email / Phone Number",
  "username_or_email": "Username Or Email",
  "username_or_password_not_correct": "Username or password not correct",
  "username_or_phone_number": "Username or Phone Number",
  "verify": "Verify",
  "view": "View",
  "view_record": "View Record",
  "visual_arts": "Visual Arts",
  "vocational": "Vocational",
  "volleyball": "Volleyball",
  "voters_id": "Voters ID",
  "wassce": "WASSCE",
  "welcome": "Welcome",
  "withdrawal": "Withdrawal",
  "would_you_like_to_represent_institution": "Would you like to represent GCTU?",
  "year_of_birth": "Year of Birth",
  "yes": "Yes",
  "your_account_has_been_blocked": "Your account has been blocked",
  "your_account_has_been_created": "Your account has been created.",
  "your_account_is_not_active__please_contact_system_administrator_for_more_information": "Your account is not active. Please contact system administrator for more information",
  "your_account_is_waiting_for_review": "Your account is waiting for review",
  "your_password_confirmation_is_not_consistent": "Your password confirmation is not consistent",
  "your_password_has_been_changed_successfully": "Your password has been changed successfully",
  "your_password_has_expired__please_reset_your_password_to_continue": "Your password has expired. Please reset your password to continue.",
  "your_password_reset_was_not_completed": "Your password reset was not completed",

  "welcome_to_gctu": "Welcome to GCTU",
  "create_an_account": "Create an Account",
  "at_least_one_lowercase": "At least one lowercase",
  "at_least_one_uppercase": "At least one uppercase",
  "at_least_one_numeric_value": "At least one numeric value",
  "minimum_8_characters": "Minimum 8 characters",
  "suggestions": "Suggestions",
  "create_your_new_password": "Create your new password",
  "all_fields_are_required": "All fields are required",
  "all_rights_reserved": "All right reserved",
  "application_results": "Application Results",
  "help_and_support": "Help and Support",
  "your_dashboard": "Your Dashboard",
  "application_form": "Application Form",
  "bio_information": "Bio Information",
  "family_information": "Family Information",
  "academic_history": "Academic",
  "programme_selections": "Selection of Programmes",
  "funding_or_scholarship": "Funding/Scholarship",
  "payment": "Payment",
  "pending": "Pending",
  "completed": "Completed",
  "click_button_to_load_applcaition_form": "Your application form is ready. Apply to 3 universities with one purchase. Click button to open application form",
  "open_application_form": "Open Application Form",
  "important_notice": "Important Notice",
  "academic_year": "Academic Year",
  "form_fees": "Form Fees",
  "deadline_for_submission": "Submission Deadline",
  "application_status_overview": "Application Status Overview",
  "your_selections": "Your Selections",
  "save_and_continue": "Save and Continue",
  "date_of_birth": "Date of Birth",
  "day": "Day",
  "month": "Month",
  "year": "Year",

  "personal_information": "Personal Information",
  "primary_contact": "Primary Contact",
  "please_provide_a_valid_phone_number": "Please provide a valid phone number. This will be your primary contact number.",
  "address_of_applicant": "Address of Applicant",
  "sponsors_details": "Sponsor's Details",
  "guardian_parent_next_of_kin_information": "Guardian / Parent / Next of Kin Information",
  "sports_information": "Sports Information",
  "you_can_select_multiple_disciplines": "You can select multiple disciplines",
  "disability_special_needs_information": "Disability (Special Needs Information)",
  "employment_information": "Employment Information",
  "identification": "Identification",
  "upload_passport_size_photograph": "Upload Passport Size Photograph",
  "the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB": "The maximum size of your document must not be more than 10MB. Supported file formats: ",
  "click_here_to_view_uploaded_document": "View Uploaded Document",
  "image_preview": "Image Preview",
  "fathers_information": "Father's Information",
  "mothers_information": "Mother's Information",
  "tertiary_institution_information": "Tertiary Institution Information",
  "shs_information": "SHS Information",
  "country_or_location": "Country or Location",
  "in_ghana": "In Ghana",
  "outside_ghana": "Outside Ghana",
  "exams_sitting": "Exams Sitting",
  "entry_information": "Entry Information",
  "exams_type": "Exams Type",
  "first_institution": "First Institution",
  "second_institution": "Second Institution",
  "third_institution": "Third Institution",
  "first_choice_institution": "First Choice Institution",
  "second_choice_institution": "Second Choice Institution",
  "third_choice_institution": "Third Choice Institution",
  "select_first_choice_programme": "Select First Choice Programme",
  "select_second_choice_programme": "Select Second Choice Programme",
  "select_third_choice_programme": "Select Third Choice Programme",
  "for_courier_services": "For Courier Services",
  "permanent_home_address": "Permanent Home Address",
  "specify_senior_high_school_outside_ghana": "Specify Senior High School (Outside Ghana)",
  "shs_outside_ghana": "SHS (Outside Ghana)",
  "confirm_index_number": "Confirm Index Number",
  "specify_tertiary_outside_ghana": "Specify Tertiary Institution (Outside Ghana)",
  "tertiary_outside_ghana": "Tertiary (Outside Ghana)",
  "apply_now": "Apply Now",

  "hearing_impairment": "Hearing Impairment",
  "hearing_impaired": "Hearing Impaired",
  "deaf": "Deaf",
  "mobility_physical_impairment": "Mobility (Physical) Impairment",
  "physically_handicapped": "Physically Handicapped",
  "wheelchair_bound": "Wheelchair Bound",
  "lame_in_crutches": "Lame (In Crutches)",
  "lame_on_crutches": "Lame (On Crutches)",
  "speech_or_language_impairment": "Speech or Language Impairment",
  "speech_impaired": "Speech Impaired",
  "visual_impairment": "Visual Impairment",
  "blind": "Blind",
  "partially_sighted": "Partially Sighted",
  "other_impairment": "Other Impairment",
  "help_item_1": "",
  "help_item_1_description": "",
  "help_item_2": "",
  "help_item_2_description": "",
  "help_item_3": "How many programmes can I apply for?",
  "help_item_3_description": "Applicant can apply for three programmes.",
  "help_item_4": "How do I know I have gained admission to attend the university?",
  "help_item_4_description": "An email would be sent to applicant. It is advised that applicant use valid email addresses when complete forms.",
  "help_item_5": "What do I do when I am unable to progress past the application review stage?",
  "help_item_5_description": "Please review your application and fill in the mandatory fields that are not filled in.",
  "help_item_6": "How can I contact the university?",
  "help_item_6_description": "Go to the university website for contact info",
  "help_item_7": "What are the entry requirements?",
  "help_item_7_description": "Admission requirement for entry is wassce results,ssce results with minimum grade c6. Also applicants with certificate acquired in other qualifications can apply.",

  "programme": "Programme",
  "cut_off_point": "Cut Off Point",
  "no_record_found": "No record found",
  "accept_offer": "Accept Offer",
  "status": "Status",
  "all_fields_marked_are_mandatory": "All fields marked * are mandatory",
  "names_provided_should_be_as_seen_on_your_national_id": "Names provided should be as seen on your National ID",
  "all_telephone_numbers_must_start_with_the_country_code": "All telephone numbers must start with the dial code + phone number",
  "instructions": "Instructions",

  "campus": "Campus",
  "select_campus": "Select Campus",
  "programme_sessions": "Programme Sessions",
  "select_programme_session": "Select Programme Session",
  "duplicate_institution_selection_found": "Duplicate institution selection found. Kindly check your selections",
  "duplicate_programme_selection_found": "Duplicate programme selection found. Kindly check your selections",
  "information_was_updated": "Information was updated",
  "form_saved_successfully": "Form Saved Successfully",
  "please_complete_the_form": "Please complete the form",
  "form_is_invalid": "Form is invalid",
  "undertaking": "Undertaking",
  "apply_for_funding_or_scholarship": "Apply for Student Loan, Funding or Scholarship",
  "add_new_grant": "Add New Grant",
  "you_can_add_up_to_4_exams_sittings": "You can add up-to 3 Exams Sittings. Click on + icon at the bottom of this section to add more input fields",
  "click_on_icon_at_the_bottom_to_add_more": "Click on + icon at the bottom of this section to load form",
  "third_party_agency": "Third Party Agency",
  "add_new_item": "Add New Item",
  "click_here_for_more_information": "Click here for more information",
  "kindly_provide_additional_information_for_selected_item": "Kindly provide additional information for selected item",
  "add_more": "Add More",
  "add_new": "Add New",
  "modify": "Modify",
  "remove": "Remove",
  "are_you_sure_you_want_to_remove_this_item_from_your_list": "Are you sure you want to remove this item from your list?",
  "confirm_action": "Confirm Action",
  "no_additional_fields_found": "No additional fields found for modification",
  "item_removed_successfully": "Item removed successfully",
  "review_and_submit": "Review and Submit",
  "submit_application": "Submit Application",
  "you_will_not_be_able_make_any_changes_after_submission": "You will not be able make any changes after submission",
  "submit_application_for_review": "Submit Application for review",
  "applicant": "Applicant",
  "application_has_been_submitted_successfully": "Application has been submitted successfully",
  "confirmation": "Confirmation",
  "are_you_sure_you_want_to_submit_this_application": "Are you sure you want to submit this application? You will not be able make any changes after submission",
  "application_form_fee_is_only": "Application Form Fee is only",
  "select_any_of_the_payment_methods_available_and_provide_required_information": "Select any of the payment methods available and provide the required information.",
  "telephone_wallet_number": "Telephone/Wallet Number",
  "payment_method": "Payment Method",
  "card_number": "Card Number",
  "card_holder_name": "Card Holder Name",
  "cvv": "Cvv",
  "expiry_month": "Expiry Month",
  "expiry_year": "Expiry Year",
  "payment_was_successful": "Payment successfully completed",
  "proceed": "Proceed",
  "awaiting_payment_authorization_and_confirmation": "Awaiting payment authorization and confirmation...",
  "payment_authorization_request_sent": "Payment authorization request sent ...!",
  "to_complete_payment_you_will_receive_a_prompt_on_your_mobile_device": "You will receive a prompt on your mobile device to complete payment. Enter your wallet PIN to confirm this payment.",
  "after_entering_your_pin_kindly_wait_for_a_confirmation_text": "After entering your PIN, kindly wait for a confirmation text from GCTUOnline with payment TransactionID, then click 'I have Paid'",
  "filter_by_institution": "Filter by Institution",
  "filter_by_programme": "Filter by Programme",
  "filter_by_cut_off_aggregate": "Filter by Cut Off Aggregate",
  "applied": "Applied",
  "application_form_created": "Application Form Created",
  "application_submitted": "Application Submitted",
  "required_electives": "Required Electives",
  "select_programme_options": "Select Programme Option(s)",
  "telephone_wallet_number_must_be_ten_digit": "If you are using the Mobile Wallet payment method, kindly ensure to provide a valid mobile wallet number.",
  "you_have_not_completed_your_application_form": "You have not completed your application form. To view your application results, kindly complete the application",
  "please_come_back_later": "Please come back later. Thank you",
  "are_you_sure_you_want_to_accept_this_offer": "Are you sure you want to accept this offer? You will not be able to change your choice after this.",
  "by_clicking_yes_you_confirm_your_application_programme_choice_as": "By clicking Yes, you confirm your Application Programme Choice as",
  "website": "Website",
  "location": "Location",
  "cut_off_point": "Cut Off Point",
  "available_sessions": "Available Sessions",
  "programme_campuses": "Programme Campuses",
  "affiliated_institution": "Affiliated Institution",
  "website_url": "Website Url",
  "address": "Address",
  "scenario": "Scenario",
  "programme_name": "Programme Name",
  "institution_name": "Institution Name",
  "download_letter": "Download Letter",
  "i_have_paid": "I have Paid",
  "application_summary": "Application Summary",
  "must_start_with_the_country_code": "Must start with the dial code + phone number. Eg: 233209013836",
  "click_here_to_provide_application_form_data": "It is required to provide further information on the fund's/scholarship's website to complete application. Click here to continue",
  "minimum_requirements_for_admission_to_tertiary": "Minimum Requirements for Admission to Tertiary Education Institutions in Ghana",
  "Further_to_earlier_notices_issued_phrase": "Further to earlier notices issued by the National Accreditation Board (NAB) on the above, and in consultation with the relevant stakeholders, the tertiary education regulatory bodies wish to restate the acceptable minimum entry requirements for admission to the various tertiary education institutions.",
  "minimum_entry_requirements": "Minimum Entry Requirements",
  "export_letter": "Export Letter",
  "payment_failed_auto_refund_initiated_please_check_funds": "Payment Failed. Auto Refund Initiated. Please check funds in the account or you can wait 5 minutes and try again",
  "please_verify_first_time_use_of_card_with_amount_deducted_from_bank_account": "Please verify first time use of Card with amount deducted from Bank Account",
  "an_amount_of_money_has_been_deducted_from_your_bank_account": "An amount of money has been deducted from your Bank Account. Kindly enter the exact amount in the space below",
  "card_will_be_blocked_after_3_failed_verification_attempts": "NOTE: Card will be blocked after 3 failed verification attempts",
  "fathers_firstname": "Father's First Name",
  "fathers_lastname": "Father's Last Name (Surname)",
  "mothers_firstname": "Mother's First Name",
  "mothers_lastname": "Mother's Last Name (Surname)",
  "guardians_firstname": "Guardian's First Name",
  "guardians_lastname": "Guardian's Last Name (Surname)",
  "success": "Success",
  "kindly_reload_page_if_dropdown_not_showing": "Kindly reload page if dropdown list is not populated",
  "tertiary_name_outside": "Tertiary Name (Outside Ghana)",
  "wassce_for_school_candidates": "WASSCE for school candidates. First written in 2006",
  "wassce_for_private_candidates": "WASSCE for Private Candidate. First written in 2007",
  "ssce_for_school_candidates": "SSCE for school candidates. First written in 1993 and ended in 2005",
  "ssce_for_private_candidates": "SSCE for Private Candidate. First written in 1993 and ended in 2005",
  "advanced_business_certificate_examination": "Advanced Business Certificate Examination",
  "general_business_certificate_examination": "General Business Certificate Examination",
  "verify_results": "Verify Exams Sitting Results",
  "click_to_verify_your_sitting_details": "Click the 'Verify Exams Sitting Results' button to verify your exams sitting details. This message will disappear when verification is successful. You must do this to ensure the correct information is submitted",
  "you_have_2_attempts_to_validate_ghana_card_number": "NOTE: You have 2 attempts to validate Ghana Card Number. Kindly ensure you have entered the correct ID Number before clicking the Validate button. Your name will appear in the First and Last name fields below when verification is successful.",
  "select_faculty": "Select Faculty/School",
  "my_application": "My Application",
  "first_choice": "First Choice",
  "second_choice": "Second Choice",
  "third_choice": "Third Choice",
  "serial_number": "Serial Number",
  "pin": "Pin",
  "birth_certificate": "Birth Certificate",
  "considered_for_scholarship": "Would you like to be considered for Scholarship?",
  "application_information": "Application Information",
  "application_reference_number": "Application Reference Number",
  "form_created": "Form Created",
  "kindly_select_type_of_application": "Kindly select type of application",
  "undergraduate": "Undergraduate",
  "postgraduate": "Postgraduate",
  "mature": "Mature",
  "topup": "Topup",
  "add_subject": "Add subject/course",
  "subject": "Subject",
  "grade": "Grade",
  "add_another_sitting": "Add another sitting",
  "you_must_enter_your_grades_below": "You MUST enter your grades below for this sitting. Skip this entry if you are awaiting your results. Please make you exact grades on your results sheet/slip",
  "proof_of_nationality": "Proof of Nationality",
  "other_exams": "OTHER",
  "exams_type_not_listed_here": "Exams type not listed (Foreign Student)",
  "upload_gtec_evaluation_document": "Upload GTEC Evaluation document",
  "if_your_gtec_evaluation_document": "If your GTEC evaluation document is not ready, please upload the payment receipt",
  "upload_transcript": "Upload Transcript",
  "please_upload_translation_of_transcript_if_not_english": "Please upload translation of transcript if not English",
  "required_documents": "Required Documents",
  "upload_recommendation_letters": "Upload 2 recommendation letters",
  "other_qualifications": "Previous Qualifications",
  "attach_other_qualifications": "Attach previous qualification certificates (if any)",
  "view_offer": "View Offer",
  "offer_letter": "Offer Letter",
  "form_type": "Form Type",
  "back_to_home": "Back to Home",
  "view_receipt": "View or Download Receipt",
  "academic_entry_month": "Academic Entry Month",
  "learning_institution": "Learning Institution",
  "academic_month": "Academic Month",
  "view_uploaded_document": "View uploaded document",
  "recommendation_letters": "Recommendation Letters",
  "letter_of_recommendation": "Letter of Recommendation",
  "transcript": "Transcript",
  "english_proficiency": "English Proficiency",
  "previous_qualifications": "Previous Qualification(s)",
  "documents": "Documents",
  "gtec_evaluation": "GTEC Evaluation",
  "submission_date": "Submission Date",
  "reference_number": "Reference Number",
  "download_application_form": "Download Application Form",
  "visit_this_website_for_more_information_on_graduate_school": "Visit this website for more information on GCTU Graduate School",
  "after_downloading_and_completing_form_submit_to_school": "After downloading and completing application form, please submit application form to GCTU, Tesano",
  "kindly_attach_serial_number_pin_to_document": "Kindly attach serial number and pin to application form when submitting",
  "working_experience": "Working Experience",
  "institution_attended": "Institutions Attended / Qualifications",
  "you_can_add_up_to_4_records": "You can add up-to 4 records. Click on + icon at the bottom of this section to add more input fields",
  "add_another_institution": "Add another institution",
  "name_of_institution": "Name of Institution",
  "name_of_institution_other": "Name of Institution (Specify Other)",
  "statement_of_purpose": "Personal Statement / Statement of Purpose",
  "research_and_professional_experience": "Research and Professional Experience",
  "record_of_employment": "Record of Employment",
  "position": "Position",
  "employer": "Employer",
  "institution_or_organisation": "Institution or Organisation",
  "recommendation": "Recommendation",
  "selection_of_programme": "Selection of Programme",
  "funding_or_sponsorship": "Funding/Sponsorship",
  "select_programme_duration": "Select preferred duration",
  "in_collaboration_with": "In collaboration with",
  "duration": "Duration",
  "session": "Session",
  "how_many_years_of_working_experience_do_you_have": "How many years of working experience do you have?",
  "curriculum_vitae": "Curriculum Vitae",
  "download_application_summary": "Download Application Summary",
  "download_profile": "Download Profile",
  "application_has_been_closed": "Your application is closed",
  "your_application_has_been_closed": "Your application has been closed. You will not be able to modify application data until application is reopened. However if you think this was a mistake, kindly contact IT support",
  "download_letter": "Download Letter",
  "": "",
  "": "",
  "": "",
}