import { createStore } from "vuex";
import { pageComponents } from "./page_components.js";
import appStore from "./app_store.js";
import { auth } from "./auth.js";
import { exam_sittings } from "./exam_sittings.js";
import { app_config } from "./app_config.js";
import { applicant_selected_grants } from "./applicant_selected_grants.js";
import { applicants } from "./applicants.js";
import { institution_campuses } from "./institution_campuses.js";
import { institution_programmes } from "./institution_programmes.js";
import { institution_schools } from "./institution_schools.js";
import { payments } from "./payments.js";
import { third_party_agencies } from "./third_party_agencies.js";
import { third_party_agencies_grants } from "./third_party_agencies_grants.js";

const { state, getters, mutations, actions } = appStore;

const store = createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    pageComponents,
    auth,
    exam_sittings,
    app_config,
    applicant_selected_grants,
    applicants,
    institution_campuses,
    institution_programmes,
    institution_schools,
    payments,
    third_party_agencies,
    third_party_agencies_grants,
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  //strict: process.env.DEV
});
export default store;
