import { ApiService } from "../services/api";
export const applicants = {
  namespaced: true,
  state: {
    currentRecord: null,
    records: [],
    requestError: null,
    applicationDataRecord: null,
    bioDataRecord: null,
    familyDataRecord: null,
    academicDataRecord: null,
    workingExperienceDataRecord: null,
    programmeDataRecord: null,
    fundingDataRecord: null,
    facultiesList: [],
    firstProgrammesList: [],
    secondProgrammesList: [],
    thirdProgrammesList: [],
  },
  getters: {
    records(state) {
      return state.records;
    },
    currentRecord(state) {
      return state.currentRecord;
    },
    applicationDataRecord(state) {
      return state.applicationDataRecord;
    },
    bioDataRecord(state) {
      return state.bioDataRecord;
    },
    familyDataRecord(state) {
      return state.familyDataRecord;
    },
    academicDataRecord(state) {
      return state.academicDataRecord;
    },
    workingExperienceDataRecord(state) {
      return state.workingExperienceDataRecord;
    },
    programmeDataRecord(state) {
      return state.programmeDataRecord;
    },
    fundingDataRecord(state) {
      return state.fundingDataRecord;
    },
    facultiesList(state) {
      return state.facultiesList;
    },
    firstProgrammesList(state) {
      return state.firstProgrammesList;
    },
    secondProgrammesList(state) {
      return state.secondProgrammesList;
    },
    thirdProgrammesList(state) {
      return state.thirdProgrammesList;
    },
  },
  mutations: {
    setRecords(state, records) {
      state.records = records;
    },
    setCurrentRecord(state, record) {
      state.currentRecord = record;
    },
    mergeRecords(state, records) {
      state.records = state.records.concat(records);
    },
    addRecord(state, record) {
      if (Array.isArray(record)) {
        for (let index = 0; index < record.length; index++) {
          state.records.unshift(record[index]);
        }
      } else {
        state.records.unshift(record);
        state.currentRecord = record;
      }
    },
    updateRecord(state, record) {
      const item = state.records.find(
        (item) => item["applicant_id"] === record["applicant_id"]
      );
      if (item) {
        Object.assign(item, record);
      }
    },
    deleteRecord(state, id) {
      if (Array.isArray(id)) {
        id.forEach((itemId) => {
          let itemIndex = state.records.findIndex(
            (item) => item["applicant_id"] == itemId
          );
          if (itemIndex != -1) {
            state.records.splice(itemIndex, 1);
          }
        });
      } else {
        let itemIndex = state.records.findIndex(
          (item) => item["applicant_id"] == id
        );
        if (itemIndex != -1) {
          state.records.splice(itemIndex, 1);
        }
      }
    },
    setError(state, errors) {
      state.requestError = error;
    },
    setApplicationDataRecord(state, data) {
      state.applicationDataRecord = data;
    },
    setBioDataRecord(state, data) {
      state.bioDataRecord = data;
    },
    setFamilyDataRecord(state, data) {
      state.familyDataRecord = data;
    },
    setAcademicDataRecord(state, data) {
      state.academicDataRecord = data;
    },
    setWorkingExperienceDataRecord(state, data) {
      state.workingExperienceDataRecord = data;
    },
    setProgrammeDataRecord(state, data) {
      state.programmeDataRecord = data;
    },
    setFundingDataRecord(state, data) {
      state.fundingDataRecord = data;
    },
    setFacultiesList(state, data) {
      state.facultiesList = data;
    },
    setFirstProgrammesList(state, data) {
      state.firstProgrammesList = data;
    },
    setSecondProgrammesList(state, data) {
      state.secondProgrammesList = data;
    },
    setThirdProgrammesList(state, data) {
      state.thirdProgrammesList = data;
    },
  },
  actions: {
    fetchRecords: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        let url = payload.url;
        let merge = payload.merge;
        ApiService.get(url)
          .then((resp) => {
            let data = resp.data;
            if (data.records) {
              let records = data.records;
              if (merge) {
                commit("mergeRecords", records);
              } else {
                commit("setRecords", records);
              }
              resolve(data);
            } else {
              reject("invalid json data");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchRecord: ({ commit }, url) => {
      return new Promise((resolve, reject) => {
        ApiService.get(url)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    saveRecord: ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        ApiService.post(url, payload)
          .then((resp) => {
            let record = resp.data;
            commit("addRecord", record);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateRecord: ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        ApiService.post(url, payload)
          .then((resp) => {
            let record = resp.data;
            commit("updateRecord", record);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteRecord: ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let id = data.id;
        ApiService.get(url)
          .then((resp) => {
            commit("deleteRecord", id);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // APPLICATION DATA
    fetchApplicationData: ({ commit, state }, url) => {
      return new Promise((resolve, reject) => {
        if (state.applicationDataRecord == null) {
          ApiService.get(url)
            .then((resp) => {
              commit("setApplicationDataRecord", resp);
              resolve(resp);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve(state.applicationDataRecord);
        }
      });
    },
    updateApplicationData: ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        ApiService.post(url, payload)
          .then((resp) => {
            let record = resp.data;
            commit("updateRecord", record);
            commit("setApplicationDataRecord", resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // BIO DATA
    fetchBioData: ({ commit, state }, url) => {
      return new Promise((resolve, reject) => {
        if (state.bioDataRecord == null) {
          ApiService.get(url)
            .then((resp) => {
              commit("setBioDataRecord", resp);
              resolve(resp);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve(state.bioDataRecord);
        }
      });
    },
    updateBioData: ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        ApiService.post(url, payload)
          .then((resp) => {
            let record = resp.data;
            commit("updateRecord", record);
            commit("setBioDataRecord", resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // FAMILY DATA
    fetchFamilyData: ({ commit, state }, url) => {
      return new Promise((resolve, reject) => {
        if (state.familyDataRecord == null) {
          ApiService.get(url)
            .then((resp) => {
              commit("setFamilyDataRecord", resp);
              resolve(resp);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve(state.familyDataRecord);
        }
      });
    },
    updateFamilyData: ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        ApiService.post(url, payload)
          .then((resp) => {
            let record = resp.data;
            commit("updateRecord", record);
            commit("setFamilyDataRecord", resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // ACADEMIC DATA
    fetchAcademicData: ({ commit, state }, url) => {
      return new Promise((resolve, reject) => {
        if (state.academicDataRecord == null) {
          ApiService.get(url)
            .then((resp) => {
              commit("setAcademicDataRecord", resp);
              resolve(resp);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve(state.academicDataRecord);
        }
      });
    },
    updateAcademicData: ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        ApiService.post(url, payload)
          .then((resp) => {
            let record = resp.data;
            commit("updateRecord", record);
            commit("setAcademicDataRecord", resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // WORKING EXPERIENCE DATA
    fetchWorkingExperienceData: ({ commit, state }, url) => {
      return new Promise((resolve, reject) => {
        if (state.workingExperienceDataRecord == null) {
          ApiService.get(url)
            .then((resp) => {
              commit("setWorkingExperienceDataRecord", resp);
              resolve(resp);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve(state.workingExperienceDataRecord);
        }
      });
    },
    updateWorkingExperienceData: ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        ApiService.post(url, payload)
          .then((resp) => {
            let record = resp.data;
            commit("updateRecord", record);
            commit("setWorkingExperienceDataRecord", resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // PROGRAMME DATA
    fetchProgrammeData: ({ commit, state }, url) => {
      return new Promise((resolve, reject) => {
        if (state.programmeDataRecord == null) {
          ApiService.get(url)
            .then((resp) => {
              commit("setProgrammeDataRecord", resp);
              resolve(resp);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve(state.programmeDataRecord);
        }
      });
    },
    updateProgrammeData: ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        ApiService.post(url, payload)
          .then((resp) => {
            let record = resp.data;
            commit("updateRecord", record);
            commit("setProgrammeDataRecord", resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // PROGRAMME DATA
    fetchFundingData: ({ commit, state }, url) => {
      return new Promise((resolve, reject) => {
        if (state.fundingDataRecord == null) {
          ApiService.get(url)
            .then((resp) => {
              commit("setFundingDataRecord", resp);
              resolve(resp);
            })
            .catch((err) => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve(state.fundingDataRecord);
        }
      });
    },
    updateFundingData: ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        ApiService.post(url, payload)
          .then((resp) => {
            let record = resp.data;
            commit("updateRecord", record);
            commit("setFundingDataRecord", resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // GET Faculties LIST FROM DB/ STORE
    getFaculties({ commit, state }) {
      return new Promise((resolve, reject) => {
        let url = "components_data/institution_school_id_option_list";
        let loaded = false;
        // check if faculties array is empty before making request
        if (state.facultiesList.length < 1) {
          ApiService.get(url)
            .then((resp) => {
              commit("setFacultiesList", resp.data);
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    // GET PROGRAMMES LIST
    getFacultiesProgrammes: ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        let reload = data.reload;

        if (reload == true) {
          ApiService.get(url)
            .then((resp) => {
              if (payload == "1") {
                commit("setFirstProgrammesList", resp.data);
                resolve(resp);
              } else if (payload == "2") {
                commit("setSecondProgrammesList", resp.data);
                resolve(resp);
              } else {
                commit("setThirdProgrammesList", resp.data);
                resolve(resp);
              }
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          if (
            state.firstProgrammesList.length < 1 &&
            state.secondProgrammesList.length < 1 &&
            state.thirdProgrammesList.length < 1
          ) {
            ApiService.get(url)
              .then((resp) => {
                if (payload == "1") {
                  commit("setFirstProgrammesList", resp.data);
                  resolve(resp);
                } else if (payload == "2") {
                  commit("setSecondProgrammesList", resp.data);
                  resolve(resp);
                } else {
                  commit("setThirdProgrammesList", resp.data);
                  resolve(resp);
                }
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            if (payload == "1") {
              resolve(state.firstProgrammesList);
            } else if (payload == "2") {
              resolve(state.secondProgrammesList);
            } else {
              resolve(state.thirdProgrammesList);
            }
          }
        }
      });
    },
    // SUBMIT APPLICATION
    submitApplication: ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        ApiService.post(url, payload)
          .then((resp) => {
            let record = resp.data;
            commit("updateRecord", record);
            // commit("setFamilyDataRecord", resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // ACCEPT PROGRAMME OFFER
    acceptOffer: ({ commit, state }, data) => {
      return new Promise((resolve, reject) => {
        let url = data.url;
        let payload = data.payload;
        ApiService.post(url, payload)
          .then((resp) => {
            let record = resp.data;
            // commit("setFamilyDataRecord", resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
