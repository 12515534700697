export default {
  "a_level": "NIVEAU A",
  "a_link_will_be_sent_to_your_email_containing_the_information_you_need_for_your_password": "Un lien sera envoyé à votre e-mail contenant les informations nécessaires pour votre mot de passe.",
  "a_message_has_been_sent_to_your_email__kindly_follow_the_link_to_reset_your_password": "Un message a été envoyé à votre adresse électronique. Veuillez suivre le lien pour réinitialiser votre mot de passe.",
  "about_us": "A propos de nous",
  "academic_year": "Année académique",
  "account_detail": "Détail du compte",
  "account_email": "Email du compte",
  "account_name": "Nom du compte",
  "account_password": "Mot de passe du compte",
  "account_photo": "Photo du compte",
  "account_status": "Statut du compte",
  "account_type": "Type de compte",
  "account_username": "Nom d'utilisateur du compte",
  "action": "Action",

  "add_new__tabledisplaylabel": "Ajouter un nouveau {Tableau AfficherEtiquette}",
  "add_new_administrators": "Ajouter de nouveaux administrateurs",
  "add_new_app_config": "Ajouter une nouvelle configuration d'application",
  "add_new_applicant_selected_grants": "Ajouter un nouveau candidat Subventions sélectionnées",
  "add_new_applicants": "Ajouter de nouveaux candidats",
  "add_new_audit_for_applicants": "Ajouter un nouvel audit pour les candidats",
  "add_new_institution_accounts": "Ajouter de nouveaux comptes institutionnels",
  "add_new_institution_admissions_uploads": "Ajouter les téléchargements d'admissions d'une nouvelle institution",
  "add_new_institution_campuses": "Ajouter de nouveaux campus d'institutions",
  "add_new_institution_programmes": "Ajouter de nouveaux programmes d'institutions",
  "add_new_institution_schools": "Ajouter une nouvelle institution scolaire",
  "add_new_payments": "Ajouter de nouveaux paiements",
  "add_new_third_party_agencies": "Ajouter de nouvelles agences tierces",
  "add_new_third_party_agencies_grants": "Ajouter de nouvelles subventions pour les agences tierces",
  "additional_form_definition": "Définition du formulaire supplémentaire",
  "address_line_1": "Ligne d'adresse 1",
  "address_line_2": "Ligne d'adresse 2",
  "address_line_3": "Ligne d'adresse 3 ",
  "address_line_4": "Ligne d'adresse 4",
  "address_line1": "Ligne d'adresse 1",
  "address_line2": "Ligne d'adresse 2",
  "address_line3": "Ligne d'adresse 3",
  "address_line4": "Ligne d'adresse 4",
  "administrators": "Administrateurs",
  "administrators_details": "Détails sur les administrateurs",
  "agency_name": "Nom de l'agence",
  "alive": "Vivant",
  "all_rights_reserved": "Tous droits réservés",
  "allow_email_alert": "Autoriser l'alerte e-mail",
  "allow_sms_alert": "Autoriser les alertes Sms",
  "allow_two_factor_auth": "Authentification à deux facteurs",
  "already_exist": " Existe déjà !",
  "already_have_an_account": "Vous avez déjà un compte ?",
  "amount": "Montant",

  "app_config": "Configuration de l'application",
  "app_config_details": "Détails de la configuration de l'application",
  "app_config_id": "Id de l'App Config",
  "applicant_accepted_programme_id": "Candidat Accepté Id. du programme",
  "applicant_id": "Id. du demandeur",
  "applicant_selected_grant_id": "Candidat sélectionné Id. de la subvention",
  "applicant_selected_grants": "Subventions sélectionnées par les candidats",
  "applicant_selected_grants_details": "Détails des subventions sélectionnées par le candidat",
  "applicants": "Candidats",
  "applicants_detail": "Détail des candidats",
  "applicants_details": "Détails sur les candidats",
  "application_form_fee": "Frais du formulaire de demande",
  "application_ref": "Réf. d'application",
  "application_status": "Statut de la demande",
  "april": "Avril",
  "are_you_employed": "Avez-vous un emploi ?",
  "are_you_sure_you_want_to_delete_these_records": "Êtes-vous sûr de vouloir supprimer ces enregistrements ?",
  "are_you_sure_you_want_to_delete_this_record": "Êtes-vous sûr de vouloir supprimer cet enregistrement ?",
  "athletics": "Athlétisme",
  "attended_shs": "Fréquenté Shs",
  "audit_for_applicants": "Audit pour les candidats",
  "audit_for_applicants_details": "Audit pour les candidats Détails",
  "august": "Août",
  "available": "Disponible sur",
  "awaiting_results": "En attente de résultats ?",
  "baccalaureate": "BACCALAUREATE",
  "back": "Dos",
  "badminton": "Badminton",
  "baseball": "Baseball",

  "basketball": "Basketball",
  "browse": "Parcourir...",
  "business": "Entreprise",
  "campus_description": "Description du campus",
  "campus_name": "Nom du campus",
  "cancel": "Annuler",
  "capital_letter": "Lettre de la capitale",
  "certificate_url": "Url du certificat",
  "change_email": "Modifier l'e-mail",
  "change_password": "Changer le mot de passe",
  "characters_minimum": "Caractères minimum",
  "checking_availability": "Vérification de la disponibilité ...",
  "chess": "Échecs",
  "choose_files_or_drop_files_here": "Choisissez le fichier ou déposez le fichier ici",
  "click_here_to_login": "Cliquez ici pour vous connecter",
  "client_request_id": "Client Request Id",
  "close": "Fermer",
  "completed": "Complété",
  "confirm_new_password": "Confirmez le nouveau mot de passe",
  "confirm_password": "Confirmer le mot de passe",
  "congratulations": "Félicitations !",
  "contact_us": "Nous contacter",
  "continue": "Continuer",
  "country": "Pays",
  "country_of_origin": "Pays d'origine",
  "cricket": "Cricket",
  "cross_country": "Cross Country",
  "current_password": "Mot de passe actuel",
  "cut_off_point": "Cut Off Point",
  "data_imported_successfully": "Les données ont été importées avec succès",
  "date_created": "Date de création",
  "date_updated": "Date de mise à jour",
  "day_of_birth": "Jour de la naissance",
  "deceased": "Décédé",

  "december": "Décembre",
  "degree_awarded": "Diplôme délivré",
  "degree_class": "Classe de degré",
  "delete": "Supprimer",
  "delete_account": "Supprimer le compte",
  "delete_record": "Supprimer l'enregistrement",
  "delete_selected": "Supprimer la sélection",
  "delete_this_record": "Supprimer cet enregistrement",
  "description": "Description",
  "dial_code": "Composez le code",
  "did_you_attend_senior_high_school": "Avez-vous fréquenté l'école secondaire supérieure ?",
  "digital_address": "Adresse numérique",
  "disability": "Handicap",
  "disability_other": "Handicap Autre",
  "distinction": "Distinction",
  "divorced": "Divorcé",
  "do_you_do_any_sports": "Faites-vous du sport ?",
  "do_you_have_any_disability": "Avez-vous un handicap ?",
  "dob_day": "Journée du Dob",
  "dob_month": "Mois Dob",
  "dob_year": "Année Dob",
  "dont_have_an_account": "Vous n'avez pas de compte ?",
  "drivers_license": "Permis de conduire",
  "edit": "Modifier",
  "edit__tabledisplaylabel": "Modifier {TableDisplayLabel}",
  "edit_account": "Modifier le compte",
  "edit_administrators": "Modifier les administrateurs",
  "edit_app_config": "Modifier la configuration de l'application",
  "edit_applicant_selected_grants": "Modifier les subventions sélectionnées par le candidat",
  "edit_applicants": "Modifier les candidats",
  "edit_institution_accounts": "Modifier les comptes des institutions",
  "edit_institution_admissions_uploads": "Modifier les téléchargements d'admission des institutions",
  "edit_institution_campuses": "Editer les campus des institutions",

  "edit_institution_programmes": "Editer les programmes de l'institution",
  "edit_institution_schools": "Editer les établissements scolaires",
  "edit_my_account": "Modifier mon compte",
  "edit_payments": "Modifier les paiements",
  "edit_third_party_agencies": "Modifier les agences tierces",
  "edit_third_party_agencies_grants": "Modifier les subventions aux organismes tiers",
  "edit_this_record": "Modifier cet enregistrement",
  "email": "Courriel ",
  "email_address": "Adresse électronique",
  "email_address_changed_successfully": "L'adresse e-mail a été modifiée avec succès",
  "email_address_is_already_verified": "L'adresse e-mail est déjà vérifiée",
  "email_address_is_not_registered": "L'adresse électronique n'est pas enregistrée",
  "email_not_changed": "Email non modifié",
  "email_or_phone_number": "Courriel ou numéro de téléphone",
  "email_verification_completed": "La vérification de l'e-mail est terminée.",
  "email_verification_has_been_resent": "L'e-mail de vérification a été renvoyé",
  "email_verification_link_sent": "Envoi d'un lien de vérification par courriel",
  "email_verified_at": "Email vérifié à",
  "employment_address": "Adresse professionnelle",
  "employment_nature": "Nature de l'emploi",
  "employment_nature_other": "Emploi Nature Autre",
  "enter__fielddisplaylabel": "Entrer {FieldDisplayLabel}",
  "enter_account_email": "Entrer l'email du compte",
  "enter_account_name": "Entrez le nom du compte",
  "enter_account_password": "Entrez le mot de passe du compte",
  "enter_account_status": "Entrer le statut du compte",
  "enter_account_type": "Entrez le type de compte",
  "enter_account_username": "Entrez le nom d'utilisateur du compte",
  "enter_additional_form_definition": "Entrez une définition de formulaire supplémentaire",
  "enter_address_line_3": "Entrez la ligne 3 de l'adresse",
  "enter_address_line1": "Entrez l'adresse Ligne 1",
  "enter_address_line2": "Entrez l'adresse Ligne 2",
  "enter_address_line3": "Entrez l'adresse Ligne3",
  "enter_address_line4": "Entrez l'adresse Ligne 4",
  "enter_agency_name": "Entrez le nom de l'agence",
  "enter_allow_email_alert": "Entrer Allow Email Alert",
  "enter_allow_sms_alert": "Entrer Allow Sms Alert",
  "enter_allow_two_factor_auth": "Entrez Allow Two Factor Auth",
  "enter_amount": "Entrez le montant",
  "enter_applicant_accepted_programme_id": "Entrez l'Id du programme accepté par le demandeur",
  "enter_application_ref": "Entrez le numéro de la demande",
  "enter_application_status": "Saisir le statut de la demande",
  "enter_attended_shs": "Entrer Attended Shs",

  "enter_awaiting_results": "Entrer Attendre les résultats ?",
  "enter_campus_description": "Entrez la description du campus",
  "enter_campus_name": "Entrez le nom du campus",
  "enter_certificate_url": "Entrez l'URL du certificat",
  "enter_client_request_id": "Entrez l'Id de la demande du client",
  "enter_country": "Entrez le pays",
  "enter_cut_off_point": "Entrer le point de coupure",
  "enter_description": "Entrez la description",
  "enter_dial_code": "Entrez le code de composition",
  "enter_did_you_attend_senior_high_school": "Enter Avez-vous fréquenté le lycée ?",
  "enter_digital_address": "Entrer l'adresse numérique",
  "enter_disability": "Entrer l'invalidité",
  "enter_disability_other": "Entrer le handicap Autre",
  "enter_dob_day": "Entrez dans la journée Dob",
  "enter_dob_month": "Entrez le mois de la Dob",
  "enter_dob_year": "Entrez l'année Dob",
  "enter_email": "Entrez l'adresse e-mail",
  "enter_email_address": "Entrez l'adresse e-mail",
  "enter_employment_address": "Entrez l'adresse de l'emploi",
  "enter_employment_nature": "Entrez la nature de l'emploi",
  "enter_employment_nature_other": "Indiquer la nature de l'emploi Autre",
  "enter_entry_level": "Entrer au niveau d'entrée",
  "enter_entry_level_scenario": "Entrer dans le scénario du niveau d'entrée",
  "enter_exams_sittings": "Saisir les séances d'examens",
  "enter_fathers_email": "Entrez l'adresse électronique du père",
  "enter_fathers_living_status": "Indiquez le statut de vie du père",
  "enter_fathers_name": "Entrez le nom du père",
  "enter_fathers_occupation": "Entrez la profession du père",
  "enter_fathers_occupation_other": "Entrez la profession du père Autre",
  "enter_fathers_telephone": "Entrez le téléphone du père",
  "enter_first_institution_acceptance": "Entrer l'acceptation de la première institution",
  "enter_first_institution_first_programme_admission_status": "Saisissez le statut d'admission du premier établissement du premier programme",
  "enter_first_institution_first_programme_id": "Saisir l'identité de la première institution et du premier programme",
  "enter_first_institution_first_programme_options": "Entrer Première Institution Première Options de Programme",
  "enter_first_institution_first_programme_required_electives": "Entrer dans le premier établissement Premier programme électif obligatoire",
  "enter_first_institution_first_programme_session": "Entrer dans la première institution Première session du programme",
  "enter_first_institution_id": "Entrez l'identifiant de la première institution",
  "enter_first_institution_second_programme_admission_status": "Entrez le statut d'admission du premier établissement du second programme",
  "enter_first_institution_second_programme_id": "Entrez l'identifiant du programme de la première institution de la deuxième institution",
  "enter_first_institution_second_programme_options": "Saisir les options du premier établissement du deuxième programme",
  "enter_first_institution_second_programme_required_electives": "Entrer dans le premier établissement Second programme Cours obligatoires",
  "enter_first_institution_second_programme_session": "Entrer Première Institution Deuxième Programme Session",
  "enter_first_institution_third_programme_admission_status": "Entrez le statut d'admission du premier établissement du troisième programme",
  "enter_first_institution_third_programme_id": "Entrez l'identifiant du programme de la première institution de la troisième institution",
  "enter_first_institution_third_programme_options": "Entrer Première Institution Troisième Programme Options",
  "enter_first_institution_third_programme_required_electives": "Entrer Premier Établissement Troisième Programme Options obligatoires",
  "enter_first_institution_third_programme_session": "Entrer Première Institution Troisième Programme Session",
  "enter_first_programme_options": "Entrer les options du premier programme",
  "enter_firstname": "Entrez le prénom",
  "enter_gender": "Entrez le sexe",

  "enter_grant_additional_info": "Entrez des informations supplémentaires sur la subvention",
  "enter_grant_description": "Entrez la description de la subvention",
  "enter_grant_name": "Entrez le nom de la subvention",
  "enter_grant_type": "Entrez le type de subvention",
  "enter_guardian_address1": "Entrez l'adresse du tuteur1",
  "enter_guardian_address2": "Entrez l'adresse du tuteur2",
  "enter_guardian_address3": "Entrez l'adresse du tuteur3",
  "enter_guardian_email": "Entrez l'adresse e-mail du tuteur",
  "enter_guardian_name": "Entrez le nom du tuteur",
  "enter_guardian_occupation": "Entrez la profession du tuteur",
  "enter_guardian_occupation_other": "Indiquez la profession du tuteur Autre",
  "enter_guardian_telephone": "Entrez le numéro de téléphone du tuteur",
  "enter_has_attended_tertiary": "Entrer A fréquenté un établissement d'enseignement supérieur",
  "enter_has_vacancy": "Enter a un poste vacant",
  "enter_have_you_attended_any_tertiary_institution": "Enter Avez-vous fréquenté un établissement d'enseignement supérieur ?",
  "enter_hometown": "Entrez votre ville natale",
  "enter_hometown_region": "Entrez la région de votre ville natale",
  "enter_id_number": "Entrez le numéro d'identification",
  "enter_in_case_you_are_not_offered_admission_would_you_like_the_selected_institution_to_pass_details_of_your_application_to_affiliated_private_universities": "Enter Au cas où vous ne seriez pas admis, souhaitez-vous que l'établissement sélectionné transmette les détails de votre demande aux d'autres universités privées?",
  "enter_in_case_you_dont_meet_the_regular_cut_off_point_would_you_like_to_be_considered_for_fee_paying": "Entrer Dans le cas où vous n'atteignez pas le point de coupure régulier, voudriez-vous être considéré pour le paiement des frais ?",
  "enter_index_number": "Entrer le numéro d'index",
  "enter_institution_additional_info": "Entrez les informations supplémentaires sur l'institution",
  "enter_institution_school_id": "Entrez l'identifiant de l'établissement scolaire",
  "enter_is_athlete": "Entrez est un athlète",
  "enter_is_awaiting_results": "Enter attend les résultats",
  "enter_is_disable": "Enter est désactivé",
  "enter_is_employed": "Entrer est employé",
  "enter_is_fee_paying": "L'entrée est payante",
  "enter_is_grant_available": "Entrer est une subvention disponible",
  "enter_last_competition": "Participer au dernier concours",
  "enter_last_shs_attended": "Entrez le dernier cours fréquenté",
  "enter_last_shs_other": "Enter Last Shs Other",
  "enter_last_shs_outside": "Entrez le dernier Shs à l'extérieur",
  "enter_last_tertiary_attended": "Entrez le dernier établissement d'enseignement supérieur fréquenté",
  "enter_lastname": "Entrez le nom de famille",
  "enter_marital_status": "Indiquez l'état civil",
  "enter_mothers_email": "Entrez l'adresse électronique de la mère",
  "enter_mothers_living_status": "Indiquez le statut de vie de la mère",
  "enter_mothers_name": "Entrez le nom de la mère",
  "enter_mothers_occupation": "Entrez la profession de la mère",
  "enter_mothers_occupation_other": "Entrez la profession de la mère Autre",
  "enter_mothers_telephone": "Entrez le téléphone de la mère",
  "enter_name": "Entrer le nom",
  "enter_national_id_number": "Entrez le numéro d'identification national",
  "enter_national_id_type": "Entrez le type d'identification nationale",
  "enter_othernames": "Entrez les autres noms",
  "enter_otp": "Entrez l'OTP",

  "enter_pass_to_affiliate": "Entrer le Pass pour l'affilié",
  "enter_password": "Entrez le mot de passe",
  "enter_payment_status": "Saisir l'état du paiement",
  "enter_ph_address_line1": "Entrer l'adresse du téléphone Ligne 1",
  "enter_ph_address_line2": "Entrer l'adresse du Ph Ligne2",
  "enter_ph_address_line3": "Entrer l'adresse du téléphone Ligne 3",
  "enter_ph_address_line4": "Entrer l'adresse du téléphone Ligne 4",
  "enter_primary_telephone": "Entrez le téléphone principal",
  "enter_programme_description": "Entrez la description du programme",
  "enter_programme_options": "Entrer dans les options du programme",
  "enter_programme_required_electives": "Entrer les cours facultatifs obligatoires du programme",
  "enter_programme_title": "Entrez le titre du programme",
  "enter_religious_denomination": "Entrez la dénomination religieuse",
  "enter_religious_denomination_other": "Entrez la dénomination religieuse Autre",
  "enter_same_address_as_ph_address": "Entrer la même adresse que l'adresse du Ph.",
  "enter_same_as_address_above": "Entrer Identique à l'adresse ci-dessus",
  "enter_same_as_personal_information": "Entrer Même que les informations personnelles",
  "enter_same_as_personal_information_above": "Entrer Même que les informations personnelles ci-dessus",
  "enter_same_bio_as_sponsor": "Entrez la même bio que le sponsor",
  "enter_scanned_national_id_card": "Entrez la carte d'identité nationale scannée",
  "enter_second_institution_acceptance": "Entrer l'acceptation de la deuxième institution",
  "enter_second_institution_first_programme_admission_status": "Entrez le statut d'admission du premier programme de la deuxième institution",
  "enter_second_institution_first_programme_id": "Entrez l'identifiant du programme de la deuxième institution",
  "enter_second_institution_first_programme_options": "Entrer dans la deuxième institution Première option de programme",
  "enter_second_institution_first_programme_required_electives": "Entrer dans le deuxième établissement Premier programme Cours obligatoires",
  "enter_second_institution_first_programme_session": "Entrer Deuxième Institution Première Session du Programme",
  "enter_second_institution_id": "Entrez le deuxième identifiant de l'institution",
  "enter_second_institution_second_programme_admission_status": "Saisissez le statut d'admission du second établissement du second programme",
  "enter_second_institution_second_programme_id": "Entrer l'identifiant de la seconde institution de la seconde émission",
  "enter_second_institution_second_programme_options": "Entrer dans un deuxième établissement Options du deuxième programme",
  "enter_second_institution_second_programme_required_electives": "Entrer dans le deuxième établissement Deuxième programme Cours obligatoires",
  "enter_second_institution_second_programme_session": "Entrer dans la deuxième institution Deuxième session du programme",
  "enter_second_institution_third_programme_admission_status": "Entrer Deuxième Institution Troisième Programme Statut d'admission",
  "enter_second_institution_third_programme_id": "Entrer la deuxième institution Troisième Id. du programme",
  "enter_second_institution_third_programme_options": "Entrer Deuxième Institution Troisième Programme Options",
  "enter_second_institution_third_programme_required_electives": "Entrer dans le deuxième établissement Troisième programme Cours obligatoires",
  "enter_second_institution_third_programme_session": "Entrer Deuxième Institution Troisième Programme Session",
  "enter_second_programme_options": "Entrer les options du deuxième programme",
  "enter_shs_course": "Entrez dans le cours de Shs",
  "enter_shs_exams_results": "Entrer les résultats des examens Shs",
  "enter_shs_from_year": "Entrer Shs De l'année",
  "enter_shs_grade": "Entrez Shs Grade",
  "enter_shs_qualification": "Entrez la qualification de Shs",
  "enter_shs_to_year": "Entrez Shs à l'année",
  "enter_sponsor_address": "Entrez l'adresse du sponsor",
  "enter_sponsor_email": "Entrez l'adresse e-mail du sponsor",
  "enter_sponsor_name": "Entrez le nom du sponsor",
  "enter_sponsor_telephone": "Entrez le numéro de téléphone du sponsor",
  "enter_sports_rep_institution": "Entrer l'institution du représentant sportif",
  "enter_street_address": "Entrez l'adresse de la rue",
  "enter_telephone": "Entrez le téléphone",
  "enter_tertiary_degree_awarded": "Entrer le diplôme tertiaire obtenu",
  "enter_tertiary_degree_class": "Entrer dans la classe des diplômes tertiaires",
  "enter_tertiary_from_year": "Entrer dans le tertiaire à partir de l'année",
  "enter_tertiary_programme": "Entrer dans un programme tertiaire",
  "enter_tertiary_reason_for_leaving": "Entrez la raison tertiaire de votre départ",
  "enter_tertiary_student_id": "Entrez l'identifiant de l'étudiant tertiaire",
  "enter_tertiary_to_year": "Entrer Tertiaire à Année",
  "enter_the_code": "Entrez le code",
  "enter_third_institution_acceptance": "Entrer l'acceptation de la troisième institution",
  "enter_third_institution_first_programme_admission_status": "Entrez le statut d'admission du premier programme de l'établissement tiers",
  "enter_third_institution_first_programme_id": "Entrer l'identifiant de la troisième institution et du premier programme",
  "enter_third_institution_first_programme_options": "Entrer dans la troisième institution Première option de programme",
  "enter_third_institution_first_programme_required_electives": "Entrer dans un troisième établissement Premier programme électif obligatoire",
  "enter_third_institution_first_programme_session": "Entrer Troisième Institution Première Session du Programme",
  "enter_third_institution_id": "Entrez l'identifiant de la troisième institution",
  "enter_third_institution_second_programme_admission_status": "Entrez Troisième Institution Deuxième Programme Statut d'admission",
  "enter_third_institution_second_programme_id": "Entrer l'identité du troisième établissement du deuxième programme",
  "enter_third_institution_second_programme_options": "Entrer dans la troisième institution Deuxième option de programme",
  "enter_third_institution_second_programme_required_electives": "Entrer dans le troisième établissement Deuxième programme Cours obligatoires",
  "enter_third_institution_second_programme_session": "Entrer Troisième institution Deuxième session du programme",
  "enter_third_institution_third_programme_admission_status": "Entrer dans l'établissement du troisième programme Statut d'admission du troisième programme",
  "enter_third_institution_third_programme_id": "Entrer l'institution tierce Id. du programme tierce",
  "enter_third_institution_third_programme_options": "Entrer dans une troisième institution Options de programme de troisième",
  "enter_third_institution_third_programme_required_electives": "Entrer dans le troisième établissement Troisième programme obligatoire",
  "enter_third_institution_third_programme_session": "Entrer Troisième Institution Troisième Programme Session",
  "enter_third_programme_options": "Entrer dans les options du troisième programme",
  "enter_title": "Entrez le titre",

  "enter_transaction_id": "Entrer l'ID de la transaction",
  "enter_txt_journey": "Entrer le voyage en Txt",
  "enter_username": "Entrez le nom d'utilisateur",
  "entry_level": "Niveau d'entrée",
  "entry_level_scenario": "Scénario d'entrée de gamme",
  "error_deleting_the_record__please_make_sure_that_the_record_exit": "Erreur lors de la suppression de l'enregistrement. Veuillez vous assurer que la sortie de l'enregistrement est terminée.",
  "error_inserting_record": "Erreur d'insertion d'un enregistrement",
  "error_processing_request": "Erreur de traitement de la demande !",
  "error_registering_user": "Erreur d'enregistrement de l'utilisateur",
  "error_sending_email__please_contact_system_administrator_for_more_info": "Erreur d'envoi d'email. Veuillez contacter l'administrateur système pour plus d'informations",
  "error_uploading_file": "Erreur de téléchargement de fichier",
  "exams_sittings": "Séances d'examens",
  "existing_qualification": "Qualification existante",
  "export": "Exportation",
  "export_csv": "Exportation CSV",
  "export_excel": "Exporter Excel",
  "export_page_fields": "Champs de la page d'exportation",
  "export_word": "Exporter Word",
  "fathers_email": "Courriel du père",
  "fathers_living_status": "Statut de vie du père",
  "fathers_name": "Nom du père",
  "fathers_occupation": "Profession du père",
  "fathers_occupation_other": "Profession du père Autre",
  "fathers_telephone": "Téléphone du père",
  "february": "Février",
  "female": "Femme",
  "file_format_not_supported": "Format de fichier non pris en charge",
  "first_choice_institution": "Institution de premier choix",
  "first_choice_programme": "Programme Premier Choix",
  "first_class": "Première classe",
  "first_institution_acceptance": "Acceptation de la première institution",
  "first_institution_first_programme_admission_status": "Premier établissement Premier programme Statut d'admission",
  "first_institution_first_programme_id": "Première institution Première identité de programme",
  "first_institution_first_programme_options": "Première institution Première option de programme",
  "first_institution_first_programme_required_electives": "Premier établissement Premier programme Cours obligatoires",
  "first_institution_first_programme_session": "Première institution Première session du programme",
  "first_institution_id": "Id. de la première institution",
  "first_institution_second_programme_admission_status": "Premier établissement Second programme Statut d'admission",
  "first_institution_second_programme_id": "Première institution Deuxième programme Id",
  "first_institution_second_programme_options": "Première institution Deuxième programme Options",
  "first_institution_second_programme_required_electives": "Premier établissement Second programme Cours obligatoires",
  "first_institution_second_programme_session": "Première institution Deuxième session du programme",
  "first_institution_third_programme_admission_status": "Premier établissement Troisième programme Statut d'admission",
  "first_institution_third_programme_id": "Première institution Troisième programme Id",
  "first_institution_third_programme_options": "Première institution Troisième programme Options",
  "first_institution_third_programme_required_electives": "Premier établissement Troisième programme Cours obligatoires",
  "first_institution_third_programme_session": "Première institution Troisième session du programme",
  "first_name": "Prénom",
  "first_programme_options": "Options du premier programme",
  "first_programme_session": "Première session du programme",
  "firstname": "Prénom",
  "football": "Football",
  "from": "De",
  "full_address_of_sponsor": "Adresse complète du commanditaire",
  "gender": "Genre",
  "general_arts": "Arts généraux",
  "ghana_card": "Carte du Ghana",
  "goalball": "Goalball",
  "grant_additional_info": "Informations supplémentaires sur les subventions",
  "grant_description": "Description de la subvention",
  "grant_name": "Nom de la subvention",
  "grant_type": "Type de subvention",
  "guardian_address1": "Adresse du tuteur1",
  "guardian_address2": "Adresse du tuteur2",
  "guardian_address3": "Adresse du tuteur3",
  "guardian_email": "Courriel du gardien",
  "guardian_name": "Nom du tuteur",
  "guardian_occupation": "Occupation du tuteur",
  "guardian_occupation_other": "Occupation du tuteur Autre",
  "guardian_telephone": "Téléphone du gardien",
  "guardians_address_line_1": "Adresse du tuteur Ligne 1",
  "guardians_address_line_2": "Adresse du tuteur Ligne 2",
  "guardians_address_line_3": "Adresse du tuteur Ligne 3",
  "guardians_email": "Courriel du tuteur",
  "guardians_name": "Nom du tuteur",
  "guardians_occupation": "Profession du tuteur",
  "guardians_telephone": "Téléphone du tuteur",
  "handball": "Handball",
  "has_attended_tertiary": "A fréquenté l'enseignement supérieur",
  "has_vacancy": "A un poste vacant",
  "have_you_attended_any_tertiary_institution": "Avez-vous fréquenté un établissement d'enseignement supérieur ?",
  "help_and_faq": "Aide et FAQ",
  "hi": "Bonjour",

  "hints___not_less_than_6_characters": "Indices : Pas moins de 6 caractères",
  "hockey": "Hockey",
  "home": "Accueil",
  "home_economics": "Économie domestique",
  "home_town": "Ville d'origine",
  "home_town_region": "Région de la ville d'origine",
  "hometown": "Ville natale",
  "hometown_region": "Région d'origine",
  "import_data": "Importer des données",
  "in_case_you_are_not_offered_admission_would_you_like_the_selected_institution_to_pass_details_of_your_application_to_affiliated_private_universities": "Au cas où vous ne seriez pas admis, souhaiteriez-vous que l'établissement sélectionné transmette les détails de votre candidature aux d'autres universités privées?",
  "in_case_you_dont_meet_the_regular_cut_off_point_would_you_like_to_be_considered_for_fee_paying": "Dans le cas où vous n'atteindriez pas le seuil d'admissibilité régulier, voudriez-vous être considéré pour le paiement des frais d'inscription ?",
  "index_number": "Numéro d'index",
  "institution_accounts": "Comptes des institutions",
  "institution_accounts_details": "Détails des comptes de l'institution",
  "institution_additional_info": "Informations supplémentaires sur l'institution",
  "institution_admissions_uploads": "Téléchargements des admissions de l'institution",
  "institution_admissions_uploads_details": "Institution Admissions Uploads Details",
  "institution_campus_id": "Institution Identifiant du campus",
  "institution_campuses": "Campus des institutions",
  "institution_campuses_detail": "Détail des campus de l'institution",
  "institution_campuses_details": "Détails des campus de l'institution",
  "institution_programme_id": "Institution Id. du programme",
  "institution_programmes": "Programmes de l'institution",
  "institution_programmes_details": "Détails des programmes de l'institution",
  "institution_school_id": "Institution Id. de l'école",
  "institution_schools": "Établissements scolaires",
  "institution_schools_details": "Détails sur les écoles de l'institution",
  "invalid_captcha_code": "Code captcha invalide",
  "invalid_request": "Demande non valide",
  "is_athlete": "est un athlète",
  "is_awaiting_results": "Attente des résultats",
  "is_disable": "Est désactivé",
  "is_employed": "Est employé",
  "is_fee_paying": "Les frais sont payants",
  "is_grant_available": "Une subvention est-elle disponible ?",
  "january": "Janvier",
  "july": "Juillet",
  "june": "Juin",
  "language": "Langue",
  "last_competition": "Dernière compétition",
  "last_name": "Nom de famille (Prénom)",
  "last_shs_attended": "Dernier Shs fréquenté",
  "last_shs_other": "Dernier Shs Autre",
  "last_shs_outside": "Dernier Shs Extérieur",
  "last_tertiary_attended": "Dernier établissement d'enseignement supérieur fréquenté",
  "lastname": "Nom de famille",
  "limit": "Limite",
  "load_more": "Charger plus",
  "loading": "Chargement...",
  "log_id": "Id du journal",
  "login": "Connexion",
  "login_completed": "Connexion terminée",
  "logout": "Déconnexion",
  "male": "Homme",
  "march": "Mars",
  "marital_status": "État civil",
  "married": "Marié à",
  "may": "Mai",
  "month_of_birth": "Mois de naissance",
  "mothers_email": "Courriel de la mère",
  "mothers_living_status": "Statut de vie de la mère",
  "mothers_name": "Nom de la mère",
  "mothers_occupation": "Profession de la mère",
  "mothers_occupation_other": "Profession de la mère Autre",
  "mothers_telephone": "Téléphone de la mère",
  "my_account": "Mon compte",
  "name": "Nom",
  "name_and_address_of_employer": "Nom et adresse de l'employeur",
  "name_of_sponsor": "Nom du sponsor",
  "national_id_number": "Numéro d'identification national",
  "national_id_type": "Type d'identité nationale",
  "nature_of_employment": "Nature de l'emploi",
  "netball": "Netball",
  "new_password": "Nouveau mot de passe",
  "new_values": "Nouvelles valeurs",
  "no": "Non",
  "no_file_selected_for_upload": "Aucun fichier sélectionné pour le téléchargement",
  "no_more_content_to_load": "Plus de contenu à charger",
  "no_record_found": "Aucun enregistrement trouvé",
  "no_record_inserted": "Aucun enregistrement inséré",
  "no_record_updated": "Aucun enregistrement mis à jour",
  "not_available": "Non disponible",
  "november": "Novembre",
  "number": "Numéro",
  "o_level": "O-LEVEL",
  "october": "Octobre",
  "of": "de",
  "old_values": "Anciennes valeurs",
  "other": "Autre",
  "other_caps": "AUTRE",
  "other_names": "Autres noms (Noms moyens)",
  "othernames": "Autres noms",
  "otp_has_been_sent_to_your_email": "L'OTP a été envoyé à votre e-mail",
  "otp_has_been_sent_to_your_phone_number_via_sms": "L'OTP a été envoyé à votre numéro de téléphone par SMS.",
  "otp_verification": "Vérification de l'OTP",
  "page": "Page",
  "pass": "Passez",
  "pass_to_affiliate": "Passez à l'affilié",
  "passport": "Passeport",
  "passport_picture": "Photo d'identité",
  "password": "Mot de passe",
  "password_reset": "Réinitialisation du mot de passe ",
  "password_reset_key_failure": "Échec de la clé de réinitialisation du mot de passe",
  "payment_id": "Id. de paiement",
  "payment_status": "Statut du paiement",
  "payments": "Paiements",

  "payments_details": "Détails des paiements",
  "ph_address_line1": "Ph Adresse Ligne1",
  "ph_address_line2": "Ph Adresse Ligne2",
  "ph_address_line3": "Ph Adresse Ligne3",
  "ph_address_line4": "Ph. Adresse Ligne4",
  "phone_number": "Numéro de téléphone",
  "photo": "Photo",
  "please_a_choose_file": "Veuillez choisir un fichier",
  "please_contact_the_system_administrator_for_more_information": "Veuillez contacter l'administrateur système pour plus d'informations",
  "please_enter_text": "Veuillez entrer le texte",
  "please_provide_the_valid_email_address_you_used_to_register": "Veuillez fournir l'adresse électronique valide que vous avez utilisée pour vous inscrire.",
  "please_verify_your_email_address_by_following_the_link_in_your_mailbox": "Veuillez vérifier votre adresse électronique en suivant le lien dans votre boîte aux lettres.",
  "please_you_can_try_reseting_your_password_by_following_these_steps": "Vous pouvez essayer de réinitialiser votre mot de passe en suivant les étapes suivantes ",
  "primary_telephone": "Téléphone principal",
  "print": "Imprimer",
  "print___pdf": "Imprimer | PDF",
  "print_page_settings": "Paramètres de la page d'impression",
  "privacy_policy": "Politique de confidentialité",
  "programme_description": "Description du programme",
  "programme_options": "Options du programme",
  "programme_required_electives": "Cours optionnels obligatoires du programme",
  "programme_title": "Titre du programme",
  "provide_new_password": "Fournir un nouveau mot de passe",
  "record_added_successfully": "Enregistrement ajouté avec succès",
  "record_deleted_successfully": "Enregistrement supprimé avec succès",
  "record_id": "Id. d'enregistrement",
  "record_updated_successfully": "Enregistrement mis à jour avec succès",
  "records": "Enregistrements",
  "register": "Registre",
  "religious_denomination": "Dénomination religieuse",
  "religious_denomination_other": "Dénomination religieuse Autre",
  "remember_me": "Souviens-toi de moi",
  "remove_page_links": "Supprimer les liens de la page",
  "request_url": "Url de la demande",
  "resend_email": "Renvoyer le courriel",
  "reset_password": "Réinitialiser le mot de passe",
  "rugby": "Rugby",
  "same_address_as_ph_address": "Même adresse que l'adresse Ph",
  "same_as_address_above": "Même adresse que ci-dessus",
  "same_as_personal_information": "Comme les informations personnelles",
  "same_as_personal_information_above": "Identique aux informations personnelles ci-dessus",
  "same_bio_as_sponsor": "Même biographie que le sponsor",
  "save_png": "Enregistrer PNG",
  "saving": "Sauver...",
  "scanned_national_id_card": "Carte nationale d'identité scannée",
  "science": "Science",
  "search": "Recherche",
  "second_choice_institution": "Institution de deuxième choix",
  "second_choice_programme": "Programme de deuxième choix",
  "second_class_lower": "Deuxième classe inférieure",
  "second_class_upper": "Deuxième classe supérieure",
  "second_institution_acceptance": "Acceptation de la deuxième institution",
  "second_institution_first_programme_admission_status": "Deuxième établissement Premier programme Statut d'admission",
  "second_institution_first_programme_id": "Deuxième institution Premier programme Id",
  "second_institution_first_programme_options": "Options du programme du deuxième établissement du premier degré",
  "second_institution_first_programme_required_electives": "Second établissement Premier programme Cours obligatoires",
  "second_institution_first_programme_session": "Deuxième institution Première session du programme",
  "second_institution_id": "Id de la deuxième institution",
  "second_institution_second_programme_admission_status": "Deuxième établissement Deuxième programme Statut d'admission",
  "second_institution_second_programme_id": "Deuxième institution Deuxième programme Id",
  "second_institution_second_programme_options": "Deuxième établissement Deuxième programme Options",
  "second_institution_second_programme_required_electives": "Deuxième établissement Deuxième programme Cours obligatoires",
  "second_institution_second_programme_session": "Deuxième institution Deuxième session du programme",
  "second_institution_third_programme_admission_status": "Deuxième établissement Troisième programme Statut d'admission",
  "second_institution_third_programme_id": "Deuxième institution Troisième programme Id",
  "second_institution_third_programme_options": "Deuxième institution Troisième programme Options",
  "second_institution_third_programme_required_electives": "Deuxième établissement Troisième programme Cours obligatoires",
  "second_institution_third_programme_session": "Deuxième institution Troisième session du programme",
  "second_programme_options": "Options du deuxième programme",
  "second_programme_session": "Deuxième session du programme",
  "select": "Sélectionnez ...",
  "select_a_file_to_import": "Sélectionnez un fichier à importer",
  "select_a_value": "Sélectionnez une valeur ...",
  "select_first_choice_institution": "Sélectionnez l'institution de premier choix ...",
  "select_first_choice_programme": "Sélectionnez le programme First Choice ...",
  "select_guardians_occupation": "Sélectionnez la profession du tuteur ...",
  "select_paper_size": "Sélectionner le format de papier",
  "select_second_choice_institution": "Sélectionnez l'institution de second choix ...",
  "select_second_choice_programme": "Sélectionnez le programme de second choix ...",
  "select_third_choice_institution": "Sélectionnez l'institution de troisième choix ...",
  "select_third_choice_programme": "Sélectionnez le programme de troisième choix ...",
  "send": "Envoyer",
  "separated": "Séparé",
  "september": "Septembre",
  "should_contain": "Doit contenir",
  "show_header": "Afficher l'en-tête",
  "shs__outside_ghana": "Shs (hors Ghana)",
  "shs_certificate": "Certificat Shs",
  "shs_course": "Cours Shs",
  "shs_exams_results": "Résultats des examens Shs",
  "shs_from_year": "Shs De l'année",
  "shs_grade": "Score agrégé de Shs",
  "shs_qualification": "Qualification Shs",
  "shs_to_year": "Shs à l'année",
  "single": "un seul",
  "sort_by": "Trier par",
  "specify_disability": "Précisez le handicap",
  "specify_all_other_disability": "Précisez tout autre handicap",
  "specify_discipline": "Précisez la discipline",
  "specify_other": "Spécifier autre",
  "specify_senior_high_school__outside_ghana": "Précisez l'école secondaire supérieure (en dehors du Ghana)",
  "sponsor_address": "Adresse du sponsor",
  "sponsor_email": "Email du sponsor",
  "sponsor_name": "Nom du sponsor",
  "sponsor_telephone": "Téléphone du sponsor",
  "sports_rep_institution": "Institution de représentation sportive",
  "sssce": "SSSCE",
  "street_address": "Adresse de la rue",
  "student_id_number": "Numéro d'identification de l'étudiant",
  "submit": "Soumettre",
  "supported_file_types": "Types de fichiers pris en charge",
  "swimming": "Baignade",
  "symbol": "Symbole",
  "table_tennis": "Tennis de table",
  "tabledisplaylabel__details": "{TableDisplayLabel} Détails",
  "technical": "Technique",
  "telephone": "Téléphone",
  "terms_and_conditions": "Modalités et conditions",
  "tertiary_degree_awarded": "Diplôme tertiaire obtenu",
  "tertiary_degree_class": "Classe de diplôme tertiaire",
  "tertiary_from_year": "Tertiaire De l'année",
  "tertiary_programme": "Programme tertiaire",
  "tertiary_reason_for_leaving": "Raison tertiaire du départ",
  "tertiary_student_id": "Identité de l'étudiant tertiaire",
  "tertiary_to_year": "Tertiaire à l'année",
  "the_dashboard": "Le tableau de bord",
  "the_email_address_is_not_registered_on_the_system": "L'adresse e-mail n'est pas enregistrée dans le système",
  "the_email_verification_link_is_not_valid": "Le lien de vérification de l'e-mail n'est pas valide",
  "third_choice_institution": "Institution de troisième choix",
  "third_choice_programme": "Programme du troisième choix",
  "third_class": "Troisième classe",
  "third_institution_acceptance": "Acceptation de la troisième institution",
  "third_institution_first_programme_admission_status": "Institution tierce Premier programme Statut d'admission",
  "third_institution_first_programme_id": "Troisième institution Premier programme Id",
  "third_institution_first_programme_options": "Troisième institution Première option de programme",
  "third_institution_first_programme_required_electives": "Troisième institution Premier programme Cours obligatoires",
  "third_institution_first_programme_session": "Troisième institution Première session du programme",
  "third_institution_id": "Id. de la troisième institution",
  "third_institution_second_programme_admission_status": "Troisième établissement Deuxième programme Statut d'admission",
  "third_institution_second_programme_id": "Troisième institution Deuxième programme Id",
  "third_institution_second_programme_options": "Troisième institution Deuxième programme Options",
  "third_institution_second_programme_required_electives": "Troisième institution Deuxième programme Cours obligatoires",
  "third_institution_second_programme_session": "Troisième institution Deuxième session du programme",
  "third_institution_third_programme_admission_status": "Troisième établissement Troisième programme Statut d'admission",
  "third_institution_third_programme_id": "Institution tierce Id. de programme tierce",
  "third_institution_third_programme_options": "Troisième institution Troisième programme Options",
  "third_institution_third_programme_required_electives": "Troisième établissement Troisième programme électif obligatoire",
  "third_institution_third_programme_session": "Troisième institution Troisième session du programme",
  "third_party_agencies": "Agences tierces",
  "third_party_agencies_detail": "Détail des agences tierces",
  "third_party_agencies_details": "Détails sur les agences tierces",
  "third_party_agencies_grant_id": "Agences tierces Grant Id",
  "third_party_agencies_grants": "Subventions aux agences tierces",
  "third_party_agencies_grants_detail": "Agences tierces - Détail des subventions",
  "third_party_agencies_grants_details": "Agences tierces - Détails des subventions",
  "third_party_agency_id": "Id de l'agence tierce",
  "third_programme_options": "Options du troisième programme",
  "third_programme_session": "Troisième session du programme",
  "timestamp": "Horodatage",
  "title": "Titre",
  "to": "A",
  "transaction_id": "Id de la transaction",
  "txt_journey": "Voyage en Txt",
  "unable_to_complete_request": "Impossible de compléter la demande.",
  "update": "Mise à jour",
  "upload_a_copy_of_the_provided_national_id": "Téléchargez une photo de la carte d'identité nationale fournie",
  "upload_passport_size_photograph": "Télécharger une photo d'identité",
  "user_agent": "Agent utilisateur",
  "user_id": "Id utilisateur",
  "user_ip": "Ip de l'utilisateur",
  "user_login": "Connexion de l'utilisateur",
  "user_registration": "Enregistrement de l'utilisateur",
  "username": "Nom d'utilisateur :",
  "username___email___phone_number": "Nom d'utilisateur / Courriel / Numéro de téléphone",
  "username_or_email": "Nom d'utilisateur ou courriel",
  "username_or_password_not_correct": "Nom d'utilisateur ou mot de passe incorrect",
  "username_or_phone_number": "Nom d'utilisateur ou numéro de téléphone",
  "verify": "Vérifier",
  "view": "Voir",
  "view_record": "Voir le dossier",
  "visual_arts": "Arts visuels",
  "vocational": "Professionnel",
  "volleyball": "Volleyball",
  "voters_id": "Identification des votants",
  "wassce": "WASSCE",
  "welcome": "Bienvenue sur",
  "withdrawal": "Retrait",
  "would_you_like_to_represent_institution": "Voulez-vous représenter l'institution ?",
  "year_of_birth": "Année de naissance",
  "yes": "Oui",
  "your_account_has_been_blocked": "Votre compte a été bloqué",
  "your_account_has_been_created": "Votre compte a été créé.",
  "your_account_is_not_active__please_contact_system_administrator_for_more_information": "Votre compte n'est pas actif. Veuillez contacter l'administrateur système pour plus d'informations",
  "your_account_is_waiting_for_review": "Votre compte est en attente de révision",
  "your_password_confirmation_is_not_consistent": "La confirmation de votre mot de passe n'est pas cohérente",
  "your_password_has_been_changed_successfully": "Votre mot de passe a été modifié avec succès",
  "your_password_has_expired__please_reset_your_password_to_continue": "Votre mot de passe a expiré. Veuillez réinitialiser votre mot de passe pour continuer.",
  "your_password_reset_was_not_completed": "La réinitialisation de votre mot de passe n'a pas été effectuée",

  "welcome_to_gctu": "Bienvenue au GCTU",
  "create_an_account": "Créer un compte",
  "at_least_one_lowercase": "Au moins une minuscule",
  "at_least_one_uppercase": "Au moins une majuscule",
  "at_least_one_numeric_value": "Au moins une valeur numérique",
  "minimum_8_characters": "Minimum 8 caractères",
  "suggestions": "Suggestions",
  "create_your_new_password": "Créez votre nouveau mot de passe",
  "all_fields_are_required": "Tous les champs sont obligatoires",
  "all_rights_reserved": "Tous droits réservés",
  "application_results": "Résultats de l'application",
  "help_and_support": "Aide et soutien",
  "your_dashboard": "Votre tableau de bord",
  "application_form": "Formulaire de demande",
  "bio_information": "Informations biologiques",
  "family_information": "Informations sur la famille",
  "academic_history": "Histoire académique",
  "programme_selections": "Sélections de programmes",
  "funding_or_scholarship": "Financement/Bourses d'études",
  "payment": "Paiement",
  "pending": "En attente",
  "completed": "Complété",
  "click_button_to_load_applcaition_form": "Votre formulaire de candidature est prêt. Posez votre candidature à 3 universités en un seul achat. Cliquez sur le bouton pour ouvrir le formulaire de candidature",
  "open_application_form": "Formulaire de demande ouvert",
  "important_notice": "Avis important",
  "academic_year": "Année académique",
  "form_fees": "Frais de formulaire",
  "deadline_for_submission": "Date limite de soumission",
  "application_status_overview": "Aperçu du statut des applications",
  "your_selections": "Vos sélections",
  "save_and_continue": "Sauvegarder et continuer",
  "date_of_birth": "Date de naissance",
  "day": "Jour",
  "month": "Mois",
  "year": "Année",

  "personal_information": "Informations personnelles",
  "primary_contact": "Contact principal",
  "please_provide_a_valid_phone_number": "Veuillez fournir un numéro de téléphone valide. Ce sera votre numéro de contact principal.",
  "address_of_applicant": "Adresse du demandeur",
  "sponsors_details": "Détails du sponsor",
  "guardian_parent_next_of_kin_information": "Renseignements sur le tuteur, le parent ou le proche parent",
  "sports_information": "Informations sportives",
  "you_can_select_multiple_disciplines": "Vous pouvez sélectionner plusieurs disciplines",
  "disability_special_needs_information": "Handicap (Informations sur les besoins spéciaux)",
  "employment_information": "Informations sur l'emploi",
  "identification": "Identification",
  "upload_passport_size_photograph": "Télécharger une photo d'identité",
  "the_maximum_size_of_your_provided_national_id_must_not_be_more_than_10MB": "La taille maximale de votre pièce d'identité nationale fournie ne doit pas dépasser 10 Mo. Formats de fichiers pris en charge : ",
  "click_here_to_view_uploaded_document": "Cliquez ici pour voir le document téléchargé",
  "image_preview": "Aperçu de l'image",
  "fathers_information": "Informations sur le père",
  "mothers_information": "Informations sur la mère",
  "tertiary_institution_information": "Informations sur les établissements tertiaires",
  "shs_information": "Informations sur les SHS",
  "country_or_location": "Pays ou lieu",
  "in_ghana": "Au Ghana",
  "outside_ghana": "En dehors du Ghana",
  "exams_sitting": "Examens en cours",
  "entry_information": "Informations sur l'entrée",
  "exams_type": "Type d'examen",
  "first_institution": "Première institution",
  "second_institution": "Deuxième institution",
  "third_institution": "Troisième institution",
  "first_choice_institution": "Institution de premier choix",
  "second_choice_institution": "Institution de deuxième choix",
  "third_choice_institution": "Institution de troisième choix",
  "select_first_choice_programme": "Sélectionnez le programme de premier choix",
  "select_second_choice_programme": "Sélectionnez le programme de second choix",
  "select_third_choice_programme": "Sélectionnez le programme de troisième choix",
  "for_courier_services": "Pour les services de courrier",
  "permanent_home_address": "Adresse du domicile permanent",
  "specify_senior_high_school_outside_ghana": "Précisez l'école secondaire supérieure (en dehors du Ghana)",
  "shs_outside_ghana": "SHS (hors Ghana)",
  "confirm_index_number": "Confirmer le numéro d'index",
  "specify_tertiary_outside_ghana": "Précisez l'établissement d'enseignement supérieur (hors du Ghana)",
  "tertiary_outside_ghana": "Tertiaire (hors Ghana)",
  "apply_now": "Postulez maintenant",

  "hearing_impairment": "Déficience auditive",
  "hearing_impaired": "Malentendants",
  "deaf": "Sourds",
  "mobility_physical_impairment": "Déficience de mobilité (physique)",
  "physically_handicapped": "Handicapés physiques",
  "wheelchair_bound": "En fauteuil roulant",
  "lame_in_crutches": "Lame (en béquilles)",
  "lame_on_crutches": "Lame (sur béquilles)",
  "speech_or_language_impairment": "Troubles de la parole ou du langage",
  "speech_impaired": "Troubles de la parole",
  "visual_impairment": "Déficience visuelle",
  "blind": "Aveugle",
  "partially_sighted": "Malvoyants",
  "other_impairment": "Autres dépréciations",
  "help_item_1": "",
  "help_item_1_description": "",
  "help_item_2": "",
  "help_item_2_description": "",
  "help_item_3": "À combien d'écoles puis-je postuler ?",
  "help_item_3_description": "Les candidats peuvent postuler à trois écoles.",
  "help_item_4": "Comment puis-je savoir si j'ai été admis dans une université donnée ?",
  "help_item_4_description": "Un courriel sera envoyé au demandeur. Il est conseillé aux candidats d'utiliser des adresses électroniques valides pour remplir les formulaires.",
  "help_item_5": "Que dois-je faire si je ne parviens pas à passer le stade de l'examen de la demande ?",
  "help_item_5_description": "Veuillez revoir votre candidature et remplir les champs obligatoires qui ne sont pas renseignés.",
  "help_item_6": "Comment puis-je contacter une université à laquelle j'ai postulé ?",
  "help_item_6_description": "Consultez le site web de l'université pour obtenir des informations de contact",
  "help_item_7": "Quelles sont les conditions d'entrée ?",
  "help_item_7_description": "Les conditions d'admission sont les résultats du wassce, les résultats du ssce avec une note minimale de c6. Les candidats ayant un certificat acquis dans d'autres qualifications peuvent également postuler.",

  "programme": "Programme",
  "cut_off_point": "Point d'arrêt",
  "no_record_found": "Aucun enregistrement trouvé",
  "accept_offer": "Accepter l'offre",
  "status": "Statut",
  "all_fields_marked_are_mandatory": "Tous les champs marqués * sont obligatoires",
  "names_provided_should_be_as_seen_on_your_national_id": "Les noms fournis doivent être ceux figurant sur votre carte d'identité nationale.",
  "all_telephone_numbers_must_start_with_the_country_code": "Tous les numéros de téléphone doivent commencer par l'indicatif du pays + le numéro de téléphone",
  "instructions": "Instructions",

  "campus": "Campus",
  "select_campus": "Sélectionnez le campus (facultatif)",
  "programme_sessions": "Programme Sessions",
  "select_programme_session": "Sélectionnez la session du programme (facultatif)",
  "duplicate_institution_selection_found": "Un double de la sélection de l'institution a été trouvé. Veuillez vérifier vos sélections",
  "duplicate_programme_selection_found": "Une sélection de programme en double a été trouvée. Veuillez vérifier vos sélections",
  "information_was_updated": "Les informations ont été mises à jour",
  "form_saved_successfully": "Le formulaire a été enregistré avec succès",
  "please_complete_the_form": "Veuillez remplir le formulaire",
  "form_is_invalid": "Le formulaire est invalide",
  "undertaking": "Entreprendre",
  "apply_for_funding_or_scholarship": "Demander un prêt étudiant, un financement ou une bourse d'études",
  "add_new_grant": "Ajouter une nouvelle bourse",
  "you_can_add_up_to_4_exams_sittings": "Vous pouvez ajouter jusqu'à 4 séances d'examen. Cliquez sur l'icône + au bas de cette section pour ajouter des champs de saisie supplémentaires.",
  "click_on_icon_at_the_bottom_to_add_more": "Cliquez sur l'icône + au bas de cette section pour charger le formulaire.",
  "third_party_agency": "Agence tierce",
  "add_new_item": "Ajouter un nouvel élément",
  "click_here_for_more_information": "Cliquez ici pour plus d'informations",
  "kindly_provide_additional_information_for_selected_item": "Veuillez fournir des informations supplémentaires sur l'article sélectionné",
  "add_more": "Ajouter",
  "add_new": "Ajouter nouveau",
  "modify": "Modifier",
  "remove": "Retirer",
  "are_you_sure_you_want_to_remove_this_item_from_your_list": "Êtes-vous sûr de vouloir supprimer cet article de votre liste ?",
  "confirm_action": "Confirmer l'action",
  "no_additional_fields_found": "Aucun champ supplémentaire n'a été trouvé pour la modification",
  "item_removed_successfully": "Article retiré avec succès",
  "review_and_submit": "Réviser et soumettre",
  "submit_application": "Soumettre la demande",
  "you_will_not_be_able_make_any_changes_after_submission": "Vous ne pourrez pas apporter de modifications après la soumission.",
  "submit_application_for_review": "Soumettre la demande d'examen",
  "applicant": "Candidat",
  "application_has_been_submitted_successfully": "La demande a été soumise avec succès",
  "confirmation": "Confirmation",
  "are_you_sure_you_want_to_submit_this_application": "Êtes-vous sûr de vouloir soumettre cette demande ? Vous ne pourrez pas faire de changements après la soumission.",
  "application_form_fee_is_only": "Les frais du formulaire de demande sont seulement",
  "select_any_of_the_payment_methods_available_and_provide_required_information": "Sélectionnez l'une des méthodes de paiement disponibles et fournissez les informations requises.",
  "telephone_wallet_number": "Numéro de téléphone/de portefeuille",
  "payment_method": "Mode de paiement",
  "card_number": "Numéro de la carte",
  "card_holder_name": "Nom du porteur de la carte",
  "cvv": "Cvv",
  "expiry_month": "Mois d'expiration",
  "expiry_year": "Année d'expiration",
  "payment_was_successful": "Le paiement a réussi",
  "proceed": "Continuez",
  "awaiting_payment_authorization_and_confirmation": "En attente de l'autorisation et de la confirmation du paiement...",
  "payment_authorization_request_sent": "Demande d'autorisation de paiement envoyée ... !",
  "to_complete_payment_you_will_receive_a_prompt_on_your_mobile_device": "Pour compléter le paiement : Vous recevrez une invite sur votre appareil mobile. Saisissez le code PIN de votre portefeuille pour confirmer ce paiement.",
  "filter_by_institution": "Filtrer par institution",
  "filter_by_programme": "Filtrer par programme",
  "filter_by_cut_off_aggregate": "Filtrer par agrégat de coupe",
  "applied": "Appliqué",
  "application_form_created": "Formulaire de demande créé",
  "application_submitted": "Demande soumise",
  "required_electives": "Cours obligatoires",
  "select_programme_options": "Choisir l'option du programme",
  "telephone_wallet_number_must_be_ten_digit": "Si vous utilisez la méthode de paiement par portefeuille mobile, veillez à fournir un numéro de portefeuille mobile valide.",
  "you_have_not_completed_your_application_form": "Vous n'avez pas rempli votre formulaire de candidature. Pour consulter les résultats de votre candidature, veuillez remplir le formulaire de candidature.",
  "please_come_back_later": "Veuillez revenir plus tard. Nous vous remercions",
  "are_you_sure_you_want_to_accept_this_offer": "Êtes-vous sûr de vouloir accepter cette offre ? Vous ne pourrez plus changer votre choix après cela.",
  "by_clicking_yes_you_confirm_your_application_programme_choice_as": "En cliquant sur Oui, vous confirmez votre choix de programme d'application en tant que",
  "website": "Site web",
  "location": "Localisation",
  "cut_off_point": "Point d'arrêt",
  "available_sessions": "Sessions offertes",
  "programme_campuses": "Programme Campus",
  "affiliated_institution": "Institution affiliée",
  "website_url": "Url du site",
  "address": "Adresse",
  "scenario": "Scénario",
  "programme_name": "Nom du programme",
  "institution_name": "Nom de l'institution",
  "download_letter": "Télécharger la lettre",
  "i_have_paid": "J'ai payé",
  "application_summary": "Résumé d'application",
  "must_start_with_the_country_code": "Doit commencer par l'indicatif du pays + numéro de téléphone. Ex: 233209013836",
  "click_here_to_provide_application_form_data": "Il est nécessaire de fournir des informations complémentaires sur le site web du fonds/de la bourse pour compléter la demande. Cliquez ici pour continuer",
  "minimum_requirements_for_admission_to_tertiary": "Conditions minimales d'admission dans les établissements d'enseignement supérieur au Ghana",
  "Further_to_earlier_notices_issued_phrase": "Suite à des avis antérieurs émis par le National Accreditation Board (NAB) sur ce sujet, et en consultation avec les parties prenantes concernées, les organismes de réglementation de l'enseignement supérieur souhaitent réaffirmer les conditions d'entrée minimales acceptables pour l'admission dans les différents établissements d'enseignement supérieur.",
  "minimum_entry_requirements": "Conditions d'entrée minimales",
  "export_letter": "Lettre d'exportation",
  "payment_failed_auto_refund_initiated_please_check_funds": "Le paiement a échoué. Remboursement automatique initié. Veuillez vérifier les fonds sur le compte ou vous pouvez attendre 5 minutes et réessayer.",
  "please_verify_first_time_use_of_card_with_amount_deducted_from_bank_account": "Veuillez vérifier que la première utilisation de la carte correspond au montant déduit du compte bancaire.",
  "an_amount_of_money_has_been_deducted_from_your_bank_account": "Une somme d'argent a été déduite de votre compte bancaire. Veuillez entrer le montant exact dans l'espace ci-dessous.",
  "card_will_be_blocked_after_3_failed_verification_attempts": "NOTE: La carte sera bloquée après 3 tentatives de vérification infructueuses.",
  "fathers_firstname": "Prénom du père",
  "fathers_lastname": "Nom du père (Prénom)",
  "mothers_firstname": "Prénom de la mère",
  "mothers_lastname": "Nom de la mère (Prénom)",
  "guardians_firstname": "Prénom du tuteur",
  "guardians_lastname": "Nom du tuteur (Prénom)",
  "success": "Succès",
  "kindly_reload_page_if_dropdown_not_showing": "Veuillez recharger la page si la liste déroulante n'est pas remplie",
  "tertiary_name_outside": "Nom du tertiaire (hors Ghana)",
  "wassce_for_school_candidates": "WASSCE pour les candidats scolaires. Première rédaction en 2006",
  "wassce_for_private_candidates": "WASSCE pour les candidats privés. Première édition en 2007",
  "ssce_for_school_candidates": "SSCE pour les candidats scolaires. Rédigé pour la première fois en 1993 et terminé en 2005",
  "ssce_for_private_candidates": "SSCE pour candidat privé. Écrit pour la première fois en 1993 et terminé en 2005",
  "advanced_business_certificate_examination": "Examen de certificat avancé de commerce (ABCE)",
  "general_business_certificate_examination": "Examen du certificat général de commerce (GBCE)",
  "verify_results": "Vérifier Les Examens",
  "click_to_verify_your_sitting_details": "Cliquez sur le bouton 'Vérifier les résultats des séances d'examen' pour vérifier les détails de vos séances d'examen. Ce message disparaît lorsque la vérification est réussie. Vous devez le faire pour vous assurer que les informations soumises sont correctes.",
  "you_have_2_attempts_to_validate_ghana_card_number": "REMARQUE : Vous avez 2 tentatives pour valider le numéro de carte du Ghana. Veuillez vous assurer que vous avez saisi le bon numéro d'identification avant de cliquer sur le bouton Valider. Votre nom apparaîtra dans les champs Prénom et Nom ci-dessous lorsque la vérification sera réussie.",
  "select_faculty": "Select Faculty/School",
  "my_application": "My Application",
  "first_choice": "First Choice",
  "second_choice": "Second Choice",
  "third_choice": "Third Choice",
  "serial_number": "Serial Number",
  "pin": "Pin",
  "birth_certificate": "Birth Certificate",
  "considered_for_scholarship": "Would you like to be considered for Scholarship?",
  "application_information": "Application Information",
  "application_reference_number": "Application Reference Number",
  "form_created": "Form Created",
  "kindly_select_type_of_application": "Kindly select type of application",
  "undergraduate": "Undergraduate",
  "postgraduate": "Postgradaute",
  "mature": "Mature",
  "topup": "Topup",
  "add_subject": "Add subject/course",
  "subject": "Subject",
  "grade": "Grade",
  "add_another_sitting": "Add another sitting",
  "you_must_enter_your_grades_below": "You MUST enter your grades below for this sitting. Skip this entry if you are awaiting your results. Please make you exact grades on your results sheet/slip",
  "proof_of_nationality": "Proof of Nationality",
  "other_exams": "OTHER",
  "exams_type_not_listed_here": "Exams type not listed (Foreign Student)",
  "upload_gtec_evaluation_document": "Upload GTEC Evaluation document",
  "if_your_gtec_evaluation_document": "If your GTEC evaluation document is not ready, please upload the payment receipt",
  "upload_transcript": "Upload Transcript",
  "please_upload_translation_of_transcript_if_not_english": "Please upload translation of transcript if not English",
  "required_documents": "Required Documents",
  "upload_recommendation_letters": "Upload 2 recommendation letters",
  "other_qualifications": "Previous Qualifications",
  "attach_other_qualifications": "Attach previous qualifications (if any)",
  "view_offer": "View Offer",
  "offer_letter": "Offer Letter",
  "form_type": "Form Type",
  "back_to_home": "Back to Home",
  "view_receipt": "View Receipt",
  "academic_entry_month": "Academic Entry Month",
  "learning_institution": "Learning Institution",
  "academic_month": "Academic Month",
  "view_uploaded_document": "View uploaded document",
  "recommendation_letters": "Recommendation Letters",
  "transcript": "Transcript",
  "english_proficiency": "English Proficiency",
  "previous_qualifications": "Previous Qualification(s)",
  "documents": "Documents",
  "gtec_evaluation": "GTEC Evaluation",
  "submission_date": "Submission Date",
  "reference_number": "Reference Number",
  "download_application_form": "Download Application Form",
  "visit_this_website_for_more_information_on_graduate_school": "Visit this website for more information on GCTU Graduate School",
  "after_downloading_and_completing_form_submit_to_school": "After downloading and completing application form, please submit application form to GCTU, Tesano",
  "kindly_attach_serial_number_pin_to_document": "Kindly attach serial number and pin to application form when submitting",
  "": "",
  "": "",
  "": "",
}