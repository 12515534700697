import { ApiService } from "../services/api";
export const app_config = {
  namespaced: true,
  state: {
    currentAppConfig: null,
    requestError: null,
  },
  getters: {
    currentAppConfig(state) {
      return state.currentAppConfig;
    },
  },
  mutations: {
    setCurrentAppConfig(state, data) {
      state.currentAppConfig = data;
    },
    setError(state, errors) {
      state.requestError = errors;
    },
  },
  actions: {
    fetchAppConfig: ({ commit, state }, url) => {
      return new Promise((resolve, reject) => {
        if (state.currentAppConfig == null) {
          ApiService.get("app_config/view")
            .then((resp) => {
              commit("setCurrentAppConfig", resp.data);
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        }else{
			resolve(state.currentAppConfig);
		}
      });
    },
  },
};
